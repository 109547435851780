.torus-left-btm {
    position: absolute;
    transform: rotate(-120deg);
    max-width: 550px;
    z-index: -1;
    left: -280px;
    bottom: -330px;
    opacity: 0.5;
    pointer-events: none;
}

.torus-left {
    position: absolute;
    max-width: 550px;
    left: -320px;
    top: 50%;
    transform: translateY(-50%) rotate(-135deg);
    opacity: 0.5;
    pointer-events: none;
}

.torus-right,
.torus-right-small {
    position: absolute;
    max-width: 550px;
    right: -390px;
    bottom: -340px;
    transform: rotate(-180deg);
    opacity: 0.5;
    pointer-events: none;
}

.torus-left-top {
    position: absolute;
    max-width: 550px;
    opacity: 0.5;
    left: -100px;
    top: -300px;
    pointer-events: none;
}

.torus-right-top {
    position: absolute;
    max-width: 550px;
    opacity: 0.5;
    right: -100px;
    top: -300px;
    pointer-events: none;
}

.torus-right-small {
    max-width: 300px;
    right: -180px;
    bottom: 0;
}

.torus-right-btm {
    position: absolute;
    max-width: 550px;
    right: -280px;
    bottom: -300px;
    transform: rotate(-135deg);
    opacity: 0.5;
    pointer-events: none;
}

.torus-center-top,
.torus-center-top-left {
    position: absolute;
    max-width: 550px;
    bottom: -390px;
    left: 50%;
    transform: translateX(-50%) rotate(-220deg);
    opacity: 0.5;
    pointer-events: none;
}

.torus-center-top-left {
    left: 0;
    z-index: 0;
}