.map {
    &__window {
        width: 60%;
        height: 594px;
        margin: 10px;

        @media only screen and (max-width: 920px) {
            width: 100%;
        }
    }

    &__nav {
        display: flex;
        margin: 0 -20px;
        flex-wrap: wrap;
        margin-bottom: 25px;

        &-item {
            flex-grow: 1;
            flex-basis: 18em;
            background-color: $color-white;
            padding: 15px;
        }

        &-link {
            display: flex;
            border: 1px solid $color-grey;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 44px 24px;
            height: 100%;
            transition: all ease 0.4s;

            &.active {
                box-shadow: 0 1px 4px rgba(0, 34, 60, 0.34);
                border: 1px solid $color-blue-one;
                background-color: rgba(0, 101, 179, 0.03);
            }

            &:hover {
                box-shadow: 0 1px 4px rgba(0, 34, 60, 0.34);
                border: 1px solid $color-blue-one;
                background-color: rgba(0, 101, 179, 0.03);
            }

            &-img {
                margin-right: 13px;
            }
        }
    }

    &__top {
        display: flex;
        flex-direction: column;
        margin-bottom: 36px;
        align-items: center;

        &-title {
            color: $color-main;
            font-family: 'GothamPro', sans-serif;
            font-size: 40px;
            font-weight: 700;
            margin: 0;
            margin-bottom: 5px;
            text-align: center;
        }

        &-text {
            max-width: 452px;
            width: 100%;
            color: $color-grey-two;
            font-family: 'Montserrat', sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            margin: 0;
            text-align: center;
        }
    }

    &__list {
        width: 40%;
        overflow-y: auto;
        max-height: 595px;
        height: 100%;
        margin: 10px;

        @media only screen and (max-width: 920px) {
            width: 100%;
        }

        &-item {
            border: 1px solid $color-grey;
            background-color: $color-white;
            padding: 25px;
            margin-bottom: 25px;

            &:last-child {
                margin-bottom: 0;
            }

            &-title {
                color: $color-main;
                font-family: "GothamPro";
                font-size: 20px;
                font-weight: 500;
                line-height: 22px;
                text-transform: uppercase;
                margin: 0;
                margin-bottom: 5px;
            }

            &-text {
                display: block;
                color: $color-main;
                font-family: 'Montserrat', sans-serif;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;

                &--modified {
                    color: $color-blue-two;
                    font-family: 'Montserrat', sans-serif;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 21px;
                    display: block;
                    padding: 5px;
                    margin: 0 5px;
                }
            }

            &-cnt {
                display: flex;
                margin: 0 -10px;
            }

            &-box {
                display: flex;
                align-items: center;
                padding: 15px 0;
            }

            &-inner {
                display: flex;
                flex-direction: column;
            }

            &-img {
                margin-right: 12px;
            }

            &-link {
                color: $color-main;
                font-family: 'Montserrat', sans-serif;
                font-size: 13px;
                font-weight: 400;
                line-height: 21px;
                margin-bottom: 5px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__content {
        background-color: $color-grey-five;
        padding: 20px 15px;
        display: flex;
        align-items: flex-start;
        margin: -5px;

        @media only screen and (max-width: 920px) {
            flex-direction: column;
        }

        @media only screen and (max-width: $phone-v) {
            padding: 20px 0;
        }
    }
}