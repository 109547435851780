/* Typography */
.text {

    & h1 {
        font-size: 50px;
        font-weight: 700;
        line-height: 53px;
        color: $color-main;
        font-family: 'GothamPro', sans-serif;
        margin: 0;
        margin-bottom: 30px;

        @media only screen and (max-width: $phone-v) {
            font-size: 40px;
            text-align: center;
        }
    }

    & h2 {
        font-size: 40px;
        font-weight: 700;
        color: $color-main;
        font-family: 'GothamPro', sans-serif;
        margin: 0;
        margin-bottom: 30px;

        @media only screen and (max-width: $phone-v) {
            font-size: 35px;
            text-align: center;
        }
    }

    & h3 {
        font-size: 35px;
        font-weight: 700;
        line-height: 45px;
        color: $color-main;
        font-family: 'GothamPro', sans-serif;
        margin: 0;
        margin-bottom: 30px;

        @media only screen and (max-width: $phone-v) {
            font-size: 27px;
            text-align: center;
        }
    }

    & h4 {
        font-size: 27px;
        font-weight: 500;
        line-height: 31px;
        color: $color-main;
        font-family: 'GothamPro', sans-serif;
        margin: 0;
        margin-bottom: 30px;

        @media only screen and (max-width: $phone-v) {
            font-size: 24px;
            text-align: center;
        }
    }

    & h5 {
        font-size: 24px;
        font-weight: 700;
        color: $color-main;
        font-family: 'GothamPro', sans-serif;
        margin: 0;
        margin-bottom: 30px;

        @media only screen and (max-width: $phone-v) {
            font-size: 20px;
            text-align: center;
        }
    }

    & h6 {
        font-size: 20px;
        font-weight: 500;
        line-height: 22px;
        color: $color-main;
        font-family: 'GothamPro', sans-serif;
        margin: 0;
        margin-bottom: 30px;

        @media only screen and (max-width: $phone-v) {
            font-size: 14px;
            text-align: center;
        }
    }

    & time {
        color: $color-grey-three;
        font-family: 'Montserrat', sans-serif;
        font-size: 13px;
        font-weight: 400;
    }

    &>p {
        color: $color-main;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        margin: 0;
        margin-bottom: 30px;
    }

    & li {
        color: $color-main;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        margin-bottom: 8px;
    }

    &__img {
        &-title {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            color: $color-white;
            font-family: "GothamPro";
            font-size: 20px;
            font-weight: 500;
            line-height: 21px;
            padding: 22px 14px;
            background-color: $color-main;

            @media only screen and (max-width: $phone-h) {
                width: 100%;
                display: block;
                position: initial;
            }

            @media only screen and (max-width: $phone-v) {
                font-size: 14px;
                padding: 10px;
            }

        }
    }

    &__box {
        position: relative;
        margin-bottom: 50px;

    }

    &__content {

        &-list {
            &-item {
                &:before {
                    content: '';
                    background-color: $color-main;
                    padding: 1px 6px;
                    display: -webkit-inline-box;
                    display: -ms-inline-flexbox;
                    display: inline-flex;
                    vertical-align: middle;
                    margin-right: 10px;
                }
            }
        }

        &-img {
            margin: 0 15px;
            align-self: center;

            @media only screen and (max-width: $tablet-h) {
                margin-bottom: 20px;
            }
        }

        &-box {
            display: flex;
            margin: 0 -15px;
            margin-bottom: 35px;

            @media only screen and (max-width: $tablet-h) {
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                margin: 0;
                margin-bottom: 35px;
            }

            &-list {
                flex: 0 1 100%;
                background-color: $color-grey-five;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin: 0 15px;
                padding: 45px;

                @media only screen and (max-width: $tablet-h) {
                    align-items: center;
                    margin: 0;
                }

                @media only screen and (max-width: $phone-v) {
                    padding: 0;
                }
            }

            &-title {
                margin-bottom: 11px !important;
            }
        }
    }

    &> :last-child {
        margin-bottom: 0;
    }

    /* floating images */
    &>img {
        margin-bottom: 1.25em;
        max-width: 100%;

        &.center {
            margin-left: auto;
            margin-right: auto;
        }
    }

    &>img[style*='float: left'] {
        margin: 0.3125em 2.1875em 2.1875em 0;
        max-width: 570px;
        width: 100%;
        // max-width: 50%;
    }

    &>img[style*='float: right'] {
        margin: 0.3125em 0 2.1875em 2.1875em;
        max-width: 570px;
        width: 100%;
        // max-width: 50%;
    }

    @media(max-width: $phone-v) {

        &>img[style*='float: left'],
        &>img[style*='float: right'] {
            margin: 0 0em 1.25em 0;
            max-width: 100%;
            float: none !important;
        }
    }

    /* end of floating images */


    & video {
        width: 100%;
        max-height: 480px;
        height: 100%;
    }

    .text__content {
        &-video {
            position: relative;
            margin-bottom: 30px;

            &-title {
                padding: 0 10px;

                @media only screen and (max-width: $phone-h) {
                    font-size: 22px;
                    margin-bottom: 10px !important;
                }

                @media only screen and (max-width: $phone-v) {
                    display: none;
                }
            }

            &-box {
                position: relative;
                width: 100%;

                &:after {
                    content: '';
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    position: absolute;
                    background-color: $color-main;
                    opacity: 0.4;
                }

                &.active {
                    &:after {
                        display: none;
                    }
                }
            }

            &-btn {
                margin: 0;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;

                &.active {
                    display: none !important;
                }
            }

            @media only screen and (max-width: $tablet-v) {
                padding: 0;
                margin-bottom: 20px;
            }
        }


    }

    /* Video embed */
    .video-embed-wrapper {
        width: 50%;
        margin: 0.5em auto 1.25em auto;

        &--left {
            float: left;
            margin-right: 1.25em;
        }

        &--right {
            float: right;
            margin-left: 1.25em;
        }

        &--full {
            width: 100%;
        }
    }

    .video-embed {
        position: relative;
        padding-bottom: 56.25%;
        /* 16:9 */
        height: 0;
        margin: 0 auto;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    /* end of Video embed */
}