.vertical-slider {
    background-color: $color-blue-one;
    // max-width: 1920px;
    width: 100%;
    // margin: 0 auto;

    &-two {
        padding: 50px 0;
    }

    &__top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 35px;
        align-items: center;

        @media only screen and (max-width: $tablet-v) {
            flex-direction: column;
            align-items: flex-start;
        }

        &-box {
            display: flex;
            align-items: center;
            margin-bottom: 30px;

            @media only screen and (max-width: $phone-h) {
                flex-wrap: wrap;
            }
        }

        &-title {
            margin: 0;
            color: $color-white;
            font-family: 'GothamPro', sans-serif;
            font-size: 40px;
            font-weight: 700;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            flex-wrap: nowrap;

            &:after {
                content: '';
                width: 1px;
                height: 20px;
                background-color: $color-white;
                display: inline-block;
                margin: 0 20px;
            }

            &--modified {
                &:after {
                    display: none;
                }
            }
        }

        &-text {
            max-width: 365px;
            width: 100%;
            opacity: 0.8;
            color: $color-white;
            font-family: 'Montserrat', sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;

            @media only screen and (max-width: $phone-h) {
                margin-bottom: 5px;
            }
        }
    }

    &__right {
        position: relative;
        width: 50%;

        @media only screen and (max-width: $tablet-h) {
            max-width: 470px;
            width: 100%;
            margin: 0 auto;
        }
    }

    &__box {
        padding: 100px 80px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @media only screen and (max-width: $tablet-h) {
            padding: 40px 0 40px 0;
            flex-direction: column;
            // overflow-y: hidden;
        }

        &-two {
            padding: 20px;

            &>.frame> {
                .slidee {
                    &>li {
                        @media only screen and (min-width: $tablet-v) {
                            font-size: 24px;
                        }
                    }

                }
            }


            @media only screen and (max-width: $tablet-h) {
                padding: 0;
            }
        }
    }

    &__list {
        border-left: 2px solid rgba(248, 248, 248, 0.2);
        padding: 0 0 8px 0;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow-y: auto;

        @media only screen and (max-width: $tablet-h) {
            border-top: none;
            border-left: none;
            display: flex;
            justify-content: center;
            width: 100%;
            margin-bottom: 50px;
            flex-direction: row;
        }

        // @media only screen and (max-width: $phone-h) {
        //     flex-direction: column;
        // }



        &-two {
            @media only screen and (max-width: $tablet-h) {
                flex-wrap: wrap;
                flex-direction: column;
                margin-bottom: 50px;
            }
        }

        &-item {
            color: $color-white;
            font-family: 'GothamPro', sans-serif;
            font-size: 35px;
            font-weight: 400;
            cursor: pointer;
            padding: 7px 0 7px 33px;
            transition: all ease 0.4s;
            transition: font-weight 0.1s ease-in-out;
            opacity: 0.7;
            white-space: nowrap;
            border-left: 5px solid transparent;

            @media only screen and (min-width: $tablet-h) {
                max-width: 355px;
                width: 100%;
            }

            @media only screen and (max-width: $tablet-h) {
                border-bottom: 5px solid transparent;
                padding: 7px 15px;
                border-left: none;
            }

            &-two {
                font-size: 20px;
                padding: 11px 0 11px 33px;

                @media only screen and (max-width: $tablet-h) {
                    padding: 7px 15px;
                    margin: 0 auto;
                    text-align: center;
                }
            }

            &:hover {
                opacity: 1;
            }

            @media only screen and (max-width: $phone-h) {
                font-size: 20px;
                margin: 0 auto;
            }

            &:last-of-type {
                margin-bottom: -10px;
            }

            &.active {
                opacity: 1;
                font-weight: 700;
                border-left: 5px solid $color-white;

                @media only screen and (max-width: $tablet-h) {
                    border-left: none;
                    border-bottom: 5px solid white;
                    padding-left: 15px;
                    padding-right: 15px;
                }

                @media only screen and (max-width: $phone-h) {
                    border-bottom: 2px solid white;
                }
            }
        }
    }

    &__content {
        opacity: 0;
        transition: all ease 0.4s;
        position: absolute;
        transform: translateY(100%);
        pointer-events: none;

        & img {
            display: block;
            margin: 0 auto;
            margin-bottom: 27px;
            margin-top: 20px;
            width: 150px;

            @media only screen and (max-width: $tablet-h) {
                width: 100px;
            }

            @media only screen and (max-width: $phone-v) {
                width: 80px;
            }
        }

        &.active {
            opacity: 1;
            position: static;
            transform: translateY(0%);
        }
    }
}

.frame {
    width: 100%;
    // height: 160px;
    padding: 0;
    margin-bottom: 25px;
    border-bottom: 1px solid rgba(248, 248, 248, 0.2);


    @media only screen and (min-width: $tablet-h) {
        border-bottom: none;
        border-left: 2px solid rgba(248, 248, 248, 0.2);
        width: 50%;
    }

    &-two {
        margin-bottom: 0;
        align-self: flex-end;
        opacity: 0;
        transition: all ease 0.4s;
        border-left: none;

        @media only screen and (max-width: $tablet-h) {
            position: absolute;
            width: 100%;
            top: 55px;
            background-color: $color-blue-two;
        }

        &.active {
            opacity: 1;
        }

        @media only screen and (min-width: $tablet-h) {
            width: 100%;
        }
    }

    &-three {
        border-bottom: 1px solid $color-grey-eight;
        margin-bottom: 25px;
    }
}

.frame {
    & .slidee {
        margin: 0;
        padding: 0;
        height: 100%;
        list-style: none;
        margin: 0px auto;
        text-align: center;

        @media only screen and (min-width: $tablet-v) {
            margin-left: -20px;
            margin-right: -20px;
        }

        @media only screen and (min-width: $tablet-h) {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin: 0;
            transform: translateZ(0px) translateX(0) !important;
            width: 100% !important;
        }
    }

    & .slidee-two {
        margin: 0;
        padding: 0;
        height: 100%;
        list-style: none;
        margin: 0px auto;
        text-align: center;
    }
}

.slidee-three {
    margin: 0 -40px;
    display: flex;
    justify-content: center;

    @media only screen and (max-width: $tablet-h) {
        margin: 0 auto;
    }
}

.slidee__item {
    float: left;
    margin: 0 5px 0 0;
    padding: 0;
    margin: 0 20px;
    opacity: 0.7;
    color: $color-grey-one;
    font-family: "GothamPro";
    font-size: 15px;
    font-weight: 500;
    line-height: 25px;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    align-self: flex-start;
    text-align: left;
    // max-width: 300px;


    &.active {
        opacity: 1;
        border-bottom: 2px solid $color-white;
    }

    @media only screen and (max-width: $tablet-h) {
        line-height: 100%;
    }

    @media only screen and (min-width: $tablet-h) {
        width: 100%;
    }

    @media only screen and (min-width: $tablet-v) {
        font-size: 35px;
        font-weight: 400;
        border-bottom: 5px solid transparent;

        &.active {
            border-bottom: 5px solid $color-white;
            // font-weight: 700;
        }
    }

    @media only screen and (min-width: $tablet-h) {
        margin: 0;
        padding: 20px;
        border-left: 5px solid transparent;
        transition: border-left 0.3s;
        border-bottom: none !important;

        &.active {
            border-left: 5px solid $color-white;
        }
    }


    &-two {
        font-size: 20px;
        padding: 11px 0 11px 33px;
        // white-space: nowrap;

        @media only screen and (max-width: $tablet-h) {
            padding: 0px 0 10px 0px;
        }
    }

    &-three {
        float: left;
        padding: 10px 65px;
        font-weight: 500;
        line-height: 22px;
        cursor: pointer;
        align-self: flex-end;
        text-align: center;
        color: $color-grey-seven;
        font-family: "GothamPro";
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        border-bottom: 4px solid transparent;
        white-space: nowrap;

        @media only screen and (max-width: $desktop) {
            padding: 10px 20px;
        }

        &.active {
            color: $color-blue-one;
            border-bottom: 4px solid $color-blue-two;
        }
    }
}

.scrollbar {
    width: 100%;
    height: 10px;
}

.scrollbar .handle {
    width: 100px;
    height: 100%;
    background: $color-white;
}

.vertical-slider__content p {
    color: $color-white;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin: 0;
}

.vertical-slider__content-two p:first-of-type {
    margin-bottom: 35px;
}

.vertical-slider__content p>span {
    font-weight: 600;
}

.vertical-slider__content ul {
    margin-bottom: 15px;
}

.vertical-slider__content ul>li {
    color: $color-white;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin-left: -10px;
}

.vertical-slider__content ul>li:before {
    content: '';
    background-color: $color-white;
    padding: 2px;
    display: inline-flex;
    vertical-align: middle;
    border-radius: 50%;
    margin-right: 10px;
}

.vertical-slider__content h4 {
    color: $color-white;
    font-family: "GothamPro";
    font-size: 27px;
    font-weight: 500;
    line-height: 31px;
    margin: 0;
    margin-bottom: 10px;

    @media only screen and (max-width: $tablet-h) {
        text-align: center;
    }

    @media only screen and (max-width: $phone-v) {
        text-align: left;
    }
}

.vertical-slider__content div:first-of-type {
    display: flex;
    justify-content: space-around;
    margin: 0 -22px;
    opacity: 0;
    transform: translateY(-50px);
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: $tablet-h) {
        margin: 0;
    }

    @media only screen and (max-width: $phone-h) {
        flex-wrap: wrap;
    }

    @media only screen and (max-width: $phone-v) {
        text-align: center;
    }

    &>div {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 22px;
        margin-bottom: 20px;
        padding: 0 16px;
        opacity: 1;
        transform: translateY(0);
        margin: 0;
        padding-bottom: 20px;
        min-width: 90px;

        @media only screen and (max-width: $phone-v) {
            flex-grow: 1;
            flex-basis: 6em;
            justify-content: center;
        }



        &>span:first-of-type {

            color: $color-white;
            font-family: 'Montserrat', sans-serif;
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 5px;
        }

        &>span:nth-of-type(2) {
            color: $color-white;
            font-size: 25px;
            font-weight: 600;
        }
    }
}

.vertical-slider__content-two div:first-of-type {
    flex-direction: row;
}