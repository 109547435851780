/* ==========================================================================
   Animations
   ========================================================================== */

@keyframes pulse {
    0% {
        transform: scale(0);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}

/* ==========================================================================
   Helper classes
   ========================================================================== */

.z-top {
    z-index: 2;
}

.center {
    text-align: center !important;

    &--tablet {
        @media only screen and (max-width: $tablet-h) {
            text-align: center !important;
        }
    }
}

.align-left {
    text-align: left !important;
}

.align-right {
    text-align: right !important;
}

.align-end {
    align-items: flex-end;
}

.align-self-end {
    align-self: flex-end;
}

.big {
    color: $color-main;
    font-family: 'GothamPro', sans-serif;
    font-size: 220px;
    font-weight: 700;

    @media only screen and (max-width: $tablet-h) {
        font-size: 150px;
    }

    @media only screen and (max-width: $phone-v) {
        font-size: 100px;
    }
}

.margin-auto-tablet {
    @media only screen and (max-width: $tablet-h) {
        margin: 0 auto;
    }
}

.overflow-hidden {
    overflow: hidden;
}

.block {
    display: block;
}

.nowrap {
    white-space: nowrap !important;
}

.no-margin {
    margin: 0 !important;
}

.margin-bottom-90 {
    margin-bottom: 90px;

    @media only screen and (max-width: $tablet-v) {
        margin-bottom: 45px;
    }

    @media only screen and (max-width: $phone-v) {
        margin-bottom: 30px;
    }
}

.margin-center {
    margin: 0 auto;
}

.no-bckgr-space {
    padding: 30px 0;
}

.margin-left {
    margin-left: auto;
}

.hidden {
    display: none !important;
}

.mc-50 {
    margin: 0 50px;

    @media only screen and (max-width: $phone-h) {
        margin: 0;
    }
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
}

.sticky-btm {
    position: fixed;
    bottom: 0;
    top: auto;
    width: 100%;
}

.wd-100 {
    width: 100% !important;
}

.p-20 {
    padding: 20px;
}

.p-20-0 {
    padding: 20px 0;
}

.margin-top-big {
    margin-top: 200px;
}

.mb-0 {
    margin-bottom: 0;
}

.mb-10 {
    margin-bottom: 10px !important;

    &--tablet {
        @media only screen and (max-width: $tablet-h) {
            margin-bottom: 10px;
        }
    }
}

.mb-15 {
    margin-bottom: 15px !important;
}

.mb-25 {
    margin-bottom: 25px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-40 {
    margin-bottom: 40px;

    &--modified {
        @media only screen and (max-width: $tablet-h) {
            margin-bottom: 20px;
        }
    }
}

.mb-50 {
    margin-bottom: 50px !important;
}

.mb-60 {
    margin-bottom: 60px !important;
}

.mb-70 {
    margin-bottom: 70px !important;

    &--modified {
        @media only screen and (max-width: $tablet-h) {
            margin-bottom: 20px !important;
        }
    }
}

.mb-80 {
    margin-bottom: 80px !important;
}

.mb-100 {
    margin-bottom: 100px !important;
}

.mt-55 {
    margin-top: 55px;
}

.mr-30 {
    margin-right: 30px;
}

.ft-14 {
    font-size: 14px;
}

.bkgr-blue {
    background-color: $color-blue-two;
}

.bkgr-main {
    background-color: $color-main;
}

.bkgr-white {
    background-color: $color-white;
}

.bckgr-grey {
    background-color: $color-grey-five;
}

.bckgr-trs {
    background-color: transparent;

    & .burger {
        background-color: transparent;

        & .burger__content,
        .burger__content:after,
        .burger__content:before {
            background-color: $color-white;
        }
    }

    &>.main-header__side>.language {
        &>.language__choices {
            background-color: transparent;
            border: none;

            &>.language__text {
                background-color: transparent;
                color: $color-white;

                &:hover {
                    background-color: $color-white;
                    color: $color-main;
                }
            }
        }

        &>.language__text--item:hover {
            color: $color-main;
            background-color: $color-white;
        }
    }
}

.color-white {
    color: $color-white !important;
}

.bkgr-main-mobile {
    overflow: hidden;

    @media only screen and (max-width: $phone-h) {
        background-color: $color-main;
    }
}

.fb-100 {
    flex-basis: 100%;
}

.z-0 {
    z-index: 0;
}

.z-2 {
    z-index: 2;
}

.hvr-underline-from-left-wt,
.hvr-underline-from-left-bl {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    overflow: hidden;
}

.hvr-underline-from-left-wt:before,
.hvr-underline-from-left-bl:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    right: 100%;
    bottom: -2px;
    background: $color-white;
    height: 3px;
    -webkit-transition-property: right;
    transition-property: right;
    -webkit-transition-duration: 0.6s;
    transition-duration: 0.6s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.non-touch .hvr-underline-from-left-wt:hover:before,
.non-touch .hvr-underline-from-left-bl:hover:before,
.hvr-underline-from-left-wt:focus:before,
.hvr-underline-from-left-bl:focus:before,
.hvr-underline-from-left-wt:active:before,
.hvr-underline-from-left-bl:active:before {
    right: 0%;
}

.hvr-underline-from-left-bl:before {
    background: $color-main;
}

.loader {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: $color-main;
    overflow: hidden;
}

.loader-inner {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loader-logo {
    margin: 0 auto;
}

.spinner {
    margin: 100px auto 0;
    width: 70px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spinner>div {
    padding: 20px;
    background-color: $color-white;
    margin: 0 5px;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0)
    }

    40% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bouncedelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}

.text-limited-wt {
    height: 4.8em;
    position: relative;
    display: block;
    transition: all ease 0.4s;
    overflow: hidden;
    margin-bottom: 50px;

    &:hover {
        color: $color-blue-one;
    }

    &:after {
        content: "";
        text-align: right;
        position: absolute;
        bottom: 0;
        top: 90%;
        right: 0;
        width: 70%;
        height: 1.6em;
        background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff 50%);

        @supports (-webkit-line-clamp: 4) {
            display: none;
        }
    }

    @supports (-webkit-line-clamp: 4) {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        max-height: 6em;
        height: auto;
        text-overflow: ellipsis;
    }
}

.grabbable:hover {
    cursor: move;
    /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

.grabbable.grabbing {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}

.no-border {
    border: none;
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    -webkit-clip-path: none;
    clip-path: none;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
    white-space: inherit;
}

.invisible {
    visibility: hidden;
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    *zoom: 1;
}

.relative {
    position: relative;
    // overflow: hidden;
}

.background-blue {
    background-color: $color-blue-one;
}

.limited {
    &-m {
        max-width: 650px !important;
    }
}