.main-footer {
    background-color: $color-main;
    overflow: hidden;

    &__content {
        display: flex;
        padding: 115px 20px 115px 20px;
        align-items: flex-start;

        @media only screen and (max-width: $tablet-h) {
            padding: 100px 20px;
            flex-wrap: wrap;
            justify-content: center;
        }

        &-box {
            display: flex;
            width: 100%;
            justify-content: space-around;
            align-items: flex-start;
            margin: 0 30px;

            @media only screen and (max-width: $tablet-h) {
                width: 100%;
                margin: 0;
            }

            @media only screen and (max-width: $tablet-v) {
                flex-wrap: wrap;
                justify-content: flex-start;
            }

            @media only screen and (max-width: $phone-v) {
                justify-content: center;
            }
        }

        &-logo {
            width: 15%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            max-width: 186px;
            margin: 0 20px;
            align-self: center;

            @media only screen and (max-width: $tablet-h) {
                margin-bottom: 60px;
            }

            &-img {
                margin-bottom: 25px;
                // min-width: 186px;
                // width: 100%;
            }
        }

        &-social {
            text-align: center;
            display: flex;
            justify-content: space-between;
            margin: -7px;
            flex-wrap: wrap;

            &-link {
                padding: 7px;
            }
        }

        &-menu {
            padding: 0 20px;

            @media only screen and (max-width: $tablet-v) {
                margin-bottom: 60px;
            }

            @media only screen and (max-width: $phone-v) {
                margin-bottom: 20px;
            }

            @media only screen and (max-width: $phone-s) {
                padding: 0;
            }

            &:last-child {
                @media only screen and (max-width: $tablet-v) {
                    margin-bottom: 0;
                }
            }

            &-title {
                margin: 10px 10px 15px 10px;
                color: $color-white;
                font-family: 'Montserrat', sans-serif;
                font-size: 15px;
                font-weight: 600;
            }

            &-link {
                color: $color-white;
                font-family: 'Montserrat', sans-serif;
                font-size: 15px;
                font-weight: 600;
                padding: 10px;

                &--light {
                    font-size: 13px;
                    font-weight: 400;
                    padding: 5px 10px;

                    @media only screen and (max-width: $phone-h) {
                        font-size: 15px;
                    }
                }
            }

            &-item {
                margin-bottom: 14px;

                &:last-child {
                    margin-bottom: 0;
                }

                &--modified {
                    margin-bottom: 5px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 30px;

        @media only screen and (max-width: $phone-h) {
            flex-wrap: wrap;
            justify-content: center;
        }

        &-center {
            display: flex;
            align-items: center;

            @media only screen and (max-width: $phone-h) {
                width: 100%;
                justify-content: center;
                margin-bottom: 14px;
            }

            &-text {
                color: $color-white;
                font-family: 'Montserrat', sans-serif;
                font-size: 13px;
                font-weight: 400;
            }
        }
    }

    &__separator {
        height: 15px;
        width: 1px;
        background-color: $color-white;
        margin: 0 10px;
        opacity: 0.7;
    }
}

.copyright {
    color: $color-white;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 700;

    @media only screen and (max-width: $phone-h) {
        margin-bottom: 14px;
    }

    &__link {
        font-family: 'Helvetica', sans-serif;
        font-size: 15px;
        font-weight: 700;
        color: $color-white;
    }

    &__text {
        color: $color-white;
        font-family: 'Montserrat', sans-serif;
        font-size: 13px;
        font-weight: 400;
    }
}