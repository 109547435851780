.info-grid {
    &__top {
        display: flex;
        justify-content: center;
        margin-bottom: 35px;
    }

    &__title {
        font-family: 'GothamPro', sans-serif;
        font-size: 35px;
        font-weight: 700;
        line-height: 45px;
        text-align: center;
        max-width: 465px;
        width: 100%;
        margin: 0;
    }

    &__item {
        max-width: 410px;
        padding: 30px 70px;
        flex-basis: 22em;
        flex-grow: 1;
        align-self: baseline;

        @media only screen and (max-width: $phone-v) {
            padding: 30px;
        }
    }

    &__box {
        background-color: $color-grey-five;
        display: flex;
        margin: 0 -30px;
        flex-wrap: wrap;
        justify-content: center;
    }

    &__img {
        margin-bottom: 35px;
    }

    &__content {
        max-width: 300px;
        width: 100%;
        flex: 1 0 auto;

        &-title {
            color: $color-main;
            font-family: "GothamPro";
            font-size: 18px;
            font-weight: 500;
            line-height: 21px;
            margin: 0;
            margin-bottom: 7px;
        }

        &-text {
            color: $color-main;
            font-family: 'Montserrat', sans-serif;
            font-size: 13px;
            font-weight: 400;
            line-height: 19px;
            margin: 0;
        }
    }
}