/* upgrade browser */
.upgrade-browser {
	position: relative;
	z-index: 10000;
	padding: 10px 60px;
	text-align: center;
	background-color: #ffe490;

	a {
		display: inline-block;
		vertical-align: middle;
		margin: 0 5px 0 0;
		width: 50px;
		height: 50px; 
		font-size: 0;
		background: url('../images/browser-sprite.jpg') no-repeat -1000px 0;

		&.chrome {
			background-position: 0px 0px;
		}
		&.ie {
			background-position: -50px 0px;
		}
		&.firefox {
			background-position: -100px 0;
		}
		&.opera {
			background-position: -150px 0;
		}
		&.safari {
			background-position: -200px 0;
		}
	}

	span {
		display: inline-block;
		margin: 10px;
		font-size: 16px;
		line-height: 18px;
	}

	&__warning {
		position: absolute;
		top: 10px;
		right: 10px;
		height: 50px;
		width: 50px;
		cursor: pointer;
		background: url('../images/browser-sprite.jpg') no-repeat -250px 0;
	}
}
/* end of upgrade browser */