// Colors
:root {
  --color-main: #00223c;
  --color-blue-one: #0065b3;
  --color-blue-two: #006cbf;
}

$color-white: #fff;
$color-black: #000;
$color-main: var(--color-main);
$color-grey: #a0a0a0;
$color-grey-one: #f8f8f8;
$color-grey-two: #626262;
$color-grey-three: #8e8e8e;
$color-grey-four: #858585;
$color-grey-five: #fbfbfb;
$color-grey-six: #fafafa;
$color-grey-seven: #3b3b3b;
$color-grey-eight: #9e9e9e;
$color-grey-nine: #f7fafd;
$color-grey-ten: #d9d9d9;
$color-grey-eleven: #9f9f9f;
$color-grey-twelve: #7d7d7d;
$color-grey-thirteen: #585858;
$color-grey-fourteen: #727272;
$color-grey-fifteen: #e1e1e1;
$color-blue-one: var(--color-blue-one);
$color-blue-two: var(--color-blue-two);
$color-red: #d71b00;

/* media queries vars */
$large-scale: 1920px;
$desktop: 1200px;
$tablet-h: 1024px;
$tablet-v: 768px;
$phone-h: 640px;
$phone-v: 480px;
$phone-s: 359px;
