.data-table {
    & table#example1 {
        border-left: 1px solid $color-grey-ten;
    }

    & .dataTables_scrollBody {
        border-top: 1px solid $color-grey-ten;
        margin-top: 25px;
    }

    &__header {
        margin: 0 15px 20px 15px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $color-grey-eight;
        overflow-x: auto;

        &--modified {
            margin: 0 0 20px 0;
        }

        &-item {
            color: $color-grey-seven;
            font-family: "GothamPro";
            font-size: 18px;
            font-weight: 500;
            line-height: 22px;
            background-color: transparent;
            display: block;
            padding: 0 24px 11px 24px;
            text-align: center;
            flex-grow: 1;
            flex-basis: 27em;
            border-bottom: 4px solid transparent;


            @media only screen and (max-width: $tablet-h) {
                width: 100%;
                min-width: 170px;
            }

            &.active {
                color: $color-blue-one;
                border-bottom: 4px solid $color-blue-two;
            }
        }
    }

    &__top {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;
    }

    &__title {
        color: $color-main;
        font-family: 'GothamPro', sans-serif;
        font-size: 35px;
        font-weight: 700;
        margin: 0;
        margin-bottom: 6px;
        text-align: center;
    }

    &__subtitle {
        color: $color-grey-two;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        margin: 0;
        max-width: 328px;
        width: 100%;
        text-align: center;
    }

    &__arrow {
        &-right {
            transform: rotate(180deg);
        }
    }

    &__select {
        max-width: 230px;
        border: none;
        color: $color-main;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
        cursor: pointer;
        background-color: $color-grey-five;
        text-align-last: center;
    }

    &__table {
        min-height: 699px;
        height: 100%;
        position: relative;

        & table.dataTable.table thead th,
        table.dataTable.table thead td {
            background-color: $color-grey-five;
            padding: 20px 25px;
            border-bottom: none !important;
        }

        &--two {
            // & .table tfoot {
            //     display: table-header-group;
            // }

            // & .table thead {
            //     display: table-row-group;
            // }
        }


    }

}



table.dataTable.table thead th,
table.dataTable.table thead td {
    background-color: $color-grey-five;
}

.ui.table thead th {
    color: $color-white !important;
    font-family: "GothamPro", sans-serif;
}

// .ui.table thead .th-modified {

// }

#example1>tbody>tr:nth-child(odd)>td,
#example1>tbody>tr:nth-child(odd)>th {
    background-color: $color-white !important;
    color: $color-main;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
}

#example1>tbody>tr:nth-child(even)>td,
#example1>tbody>tr:nth-child(even)>th {
    background-color: $color-grey-six !important;
    color: $color-main;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
}

.ui.celled.table tr td,
.ui.celled.table tr th {
    text-align: center;
    // color: $color-main !important;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
    min-width: auto !important;
}

table.dataTable tbody tr>td {
    @media only screen and (max-width: $phone-v) {
        padding: 4px 5px !important;
        max-width: 150px;
        font-size: 11px !important;
    }

}

.DTFC_LeftBodyLiner {
    overflow: hidden !important;
    width: 100% !important;
    border-left: 1px solid $color-grey-ten;
    border-right: 1px solid $color-grey-ten;
    // height: 99% !important;
    margin-top: -1px;
    padding-right: 0 !important;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>thead>tr>th,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>thead>tr>td,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>tbody>tr>th,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>tbody>tr>td {
    border-right: 1px solid $color-grey-ten;
}

#example1_filter {
    display: none;
}

.disabled {
    display: none;
}

div.dataTables_paginate {
    text-align: center
}

.dataTables_wrapper .dataTables_paginate {
    display: flex;
    justify-content: center;
    float: none !important;
}

.paginate_button.item.previous,
.paginate_button.item.next {
    background-color: $color-grey-one !important;
    border: 1px solid transparent;
    transition: all ease 0.4s;
    margin: 0 29px !important;
}

.dataTables_wrapper .dataTables_paginate .pagination .paginate_button:hover,
.dataTables_wrapper .dataTables_paginate .pagination .paginate_button.active {
    background: rgba(0, 101, 179, 0.03) !important;
    border: 1px solid $color-blue-one !important;
    box-shadow: 0 1px 4px rgba(0, 34, 60, 0.34);
    color: $color-grey-thirteen !important;
}

.dataTables_wrapper .dataTables_paginate .pagination .paginate_button.active {
    cursor: default;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover {
    border: 1px solid transparent !important;
    background-color: $color-grey-one !important;
}

.dataTables_wrapper .dataTables_paginate .pagination .paginate_button {
    color: $color-grey-thirteen !important;
    background-color: $color-grey-one;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 700;
    transition: all ease 0.4s;
    margin: 0 6px;

    @media only screen and (max-width: $phone-h) {
        margin: 6px;
    }
}

.dt-table {
    margin-bottom: 40px;
}

#example1_ellipsis {
    // opacity: 0;
    position: relative;
    background-color: transparent !important;
    display: inline-flex;
    font-size: 0;
    height: 100%;
    width: 20px;
    align-items: center;

    @media only screen and (max-width: $phone-h) {
        width: 100%;
        height: 25px;
        justify-content: center;
    }


    &:after {
        content: '';
        position: absolute;
        top: 11px;
        width: 20px;
        height: 2px;
        background-color: $color-main;
    }
}

#example1_previous,
#example1_next {
    @media only screen and (max-width: $phone-h) {
        width: 100%;
        margin: 0 !important;
    }
}

#example1_previous {
    @media only screen and (max-width: $phone-h) {
        margin-bottom: 20px !important;
    }
}

#example1_next {
    @media only screen and (max-width: $phone-h) {
        margin-top: 20px !important;
    }
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody {
    max-height: 700px !important;
}

.data-table__link {
    color: $color-main;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 400;
    text-decoration: underline;
    padding: 10px;
}

.data-table__bold {
    font-weight: 700 !important;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>thead>tr>th,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>thead>tr>td,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>tbody>tr>th,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>tbody>tr>td {
    padding: 15px 20px;
}

.not-used>a {
    font-size: 0 !important;
    padding: 0 !important;
}

.data-table--two {

    .data-table__table table.dataTable.table thead th,
    .data-table__table table.dataTable.table thead td {
        background-color: $color-main;
    }

    .dataTables_scrollBody {
        border-top: none;
        margin-top: 0;
    }

    & table.dataTable.table thead th,
    table.dataTable.table thead td {
        background-color: $color-grey-five;
        padding: 10px 18px;
        border-bottom: none !important;
    }

    & table.dataTable.no-footer {
        border-bottom: 0 !important;
    }


}