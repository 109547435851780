/* custom check radio */
.check-radio {
	display: inline-block;
	
	&__element {
		display: inline-block;
		padding-left: 1.75rem;
		position: relative;
		font-size: 1rem;
		line-height: 1.25rem;
		
		&:before,
		&:after {
			content: '';
			width: 1.25rem;
			height: 1.25rem;
			background-color: $color-white;
			border: 1px solid $color-black;
			position: absolute;
			top: 0;
			left: 0;
			transition: all 0.35s ease;
		}
	
		&:after {
			width: 0.75rem;
			height: 0.75rem;
			border: 0;
			top: 0.25rem;
			left: 0.25rem;
		}
	}

	&__input {
		display: none;
	}

	.lt-ie9 &__input {
		display: inline-block;
		vertical-align: middle;
	}

	.lt-ie9 &__element:before,
	.lt-ie9 &__element:after {
		display: none;
	}

	&__input:checked ~ &__element:after {
		background: $color-black;
	}
	&__input:disabled ~ &__element:after {
		background: $color-black;
	}
	&__input[type="radio"] ~ &__element:before,
	&__input[type="radio"] ~ &__element:after {
		border-radius: 50%;
	}
}
/* end of custom check radio */