.cta-list {
    padding: 70px 0 60px 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &__block {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: -10px;
        padding-bottom: 60px;

        @media only screen and (max-width: $tablet-v) {
            flex-direction: column;
            align-items: center;
        }

        @media only screen and (max-width: $phone-s) {
            margin: -10px 0;
        }
    }

    &__item {
        width: 50%;
        display: flex;
        align-items: center;
        color: $color-grey-one;
        font-family: "GothamPro";
        font-size: 20px;
        padding: 10px;
        font-weight: 500;
        counter-increment: counted-item;

        @media only screen and (max-width: $tablet-v) {
            font-size: 18px;
            justify-content: flex-start;
            width: 100%;
            max-width: 430px;
        }

        @media only screen and (max-width: $phone-v) {
            font-size: 16px;
        }

        @media only screen and (max-width: $phone-s) {
            padding: 10px 0;
        }

        &:before {
            content: counter(counted-item);
            font-size: 22px;
            color: $color-grey-one;
            font-family: "GothamPro";
            width: 100%;
            max-width: 44px;
            height: 44px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            border: 1px solid $color-grey-one;
            margin-right: 10px;

            @media only screen and (max-width: $tablet-v) {
                font-size: 20px;
            }

            @media only screen and (max-width: $phone-v) {
                font-size: 18px;
            }
        }
    }

    &__btn {
        appearance: none;
        display: block;
        padding: 8px 30px;
        font-size: 15px;
        color: $color-white;
        text-transform: uppercase;
        border: 1px solid $color-white;
        position: relative;
        max-width: 160px;
        text-align: center;
        width: 100%;
        z-index: 0;
        transition-property: color;
        -webkit-transition-duration: 250ms;
        transition-duration: 250ms;
        -webkit-transition-timing-function: cubic-bezier(0.425, 0, 0, 1);
        transition-timing-function: cubic-bezier(0.425, 0, 0, 1);

        &:after {
            position: absolute;
            background-color: #ffffff;
            top: 0;
            height: 100%;
            width: 0;
            left: auto;
            right: 0;
            content: '\00a0';
            -webkit-transition-property: width;
            transition-property: width;
            -webkit-transition-duration: 250ms;
            transition-duration: 250ms;
            -webkit-transition-timing-function: cubic-bezier(0.425, 0, 0, 1);
            transition-timing-function: cubic-bezier(0.425, 0, 0, 1);
            z-index: -1;
        }

        &:hover {
            color: $color-blue-one;
        }

        &:hover.cta-list__btn:after {
            width: 100%;
            left: 0;
            right: auto;
        }
    }
}