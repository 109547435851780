.download-section {
    &__top {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;
    }

    &__title {
        color: $color-main;
        font-family: 'GothamPro', sans-serif;
        font-size: 35px;
        font-weight: 700;
        margin: 0;
        margin-bottom: 6px;
        text-align: center;
    }

    &__subtitle {
        color: $color-main;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        margin: 0;
        max-width: 416px;
        width: 100%;
        text-align: center;
    }

    &__filter {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        background-color: $color-grey-five;

        &-item {
            padding: 25px;
        }

        &-text {
            color: $color-main;
            font-family: 'Montserrat', sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
        }
    }

    &__sorting {
        color: $color-main;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
        border: 0;
        background-color: $color-grey-five;
    }

    &__text {
        color: $color-main;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        margin: 0;
        margin-bottom: 15px;
    }

    &__content {
        display: flex;
        margin: 0 -20px;
        display: flex;
        flex-wrap: wrap;
    }

    &__link {
        background-color: $color-grey-five;
        height: 100%;
    }

    &__box {
        padding: 0 15px 15px 15px;
        flex: 1 0 auto;
    }

    &__item {
        max-width: 50%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        flex-basis: 19em;

        @media only screen and (max-width: $tablet-v) {
            max-width: 100%;
        }

        &-title {
            margin: 0;
            margin-bottom: 13px;
            color: $color-main;
            font-family: 'GothamPro', sans-serif;
            font-size: 24px;
            font-weight: 700;
            line-height: 28px;
        }
    }

    &__img {
        margin-bottom: 15px;
        width: 100%;
    }

    &__caption-time {
        color: $color-grey-three;
        font-family: 'Montserrat', sans-serif;
        font-size: 13px;
        font-weight: 400;
    }
}