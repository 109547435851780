.img-grid {
    &__top {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 63px;
    }

    &__title {
        color: $color-main;
        font-family: 'GothamPro', sans-serif;
        font-size: 35px;
        font-weight: 700;
        margin: 0;
        margin-bottom: 6px;
        text-align: center;
    }

    &__subtitle {
        color: $color-grey-two;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        margin: 0;
        max-width: 328px;
        width: 100%;
        text-align: center;
    }


    &__list {
        display: flex;
        flex-wrap: wrap;

        &-item {
            width: 100%;
            height: 100%;
            display: flex;

            @media only screen and (max-width: $tablet-v) {
                flex-direction: column;
                margin-bottom: 40px;

                &:nth-child(odd) {
                    flex-direction: column-reverse;
                }
            }

            &-content {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                &-box {
                    padding: 25px;

                    @media only screen and (max-width: $phone-v) {
                        padding: 25px 0;
                    }
                }
            }

            &-text {
                max-width: 440px;
                width: 100%;
                color: $color-main;
                font-family: 'Montserrat', sans-serif;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                margin: 0;
                margin-bottom: 28px;

                @media only screen and (max-width: $tablet-v) {
                    max-width: 100%;
                }
            }

            &-bkgr {
                width: 100%;
                background-color: $color-grey-five;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                // overflow: hidden;
                // margin: auto 0;
            }

            // &-img {
            //     // max-width: 585px;
            //     // max-height: 380px;
            //     // width: 100%;
            //     // height: 100%;
            // }

            &-title {
                color: $color-main;
                font-family: "GothamPro";
                font-size: 27px;
                font-weight: 500;
                line-height: 31px;
                margin: 0;
                margin-bottom: 17px;
            }
        }
    }
}