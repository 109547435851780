.product-list {
    &__top {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;
    }

    &__title {
        color: $color-main;
        font-family: 'GothamPro', sans-serif;
        font-size: 35px;
        font-weight: 700;
        margin: 0;
        margin-bottom: 6px;
        text-align: center;
    }

    &__subtitle {
        color: $color-grey-two;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        margin: 0;
        max-width: 328px;
        width: 100%;
        text-align: center;
        padding: 0 35px;
    }

    &__full {

        &--two {
            padding-bottom: 64px;
        }



        &-img {
            padding: 0 20px;

            @media only screen and (max-width: $tablet-v) {
                margin-bottom: 40px;
            }
        }

    }

}

.section-half {
    display: flex;
    justify-content: center;
    width: 100%;
}

.section-half>div>div {
    background-color: $color-grey-five;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: 35px 30px 50px;
    margin: 0 20px 0 20px;
    flex-grow: 1;
    flex-basis: 20em;
    // max-width: 500px;
    margin-bottom: 40px;
    max-height: 700px;

    @media only screen and (max-width: $tablet-v) {
        flex-direction: column;
        // flex-basis: 100%;
        flex-basis: initial;
        padding: 40px 40px 0;
        margin: 0 0 40px 0;
        max-width: 100%;
    }

    &>img {
        margin-bottom: 20px;
        margin-top: auto;
        max-width: 360px;
        width: 100%;

        @media only screen and (max-width: $tablet-v) {
            margin-bottom: 40px;

        }
    }

    &>div {
        max-width: 433px;
        width: 100%;
        padding: 0 20px;
        margin-top: auto;

        @media only screen and (max-width: $tablet-v) {
            margin-bottom: 40px;
        }

        &>img {
            margin-bottom: 45px;

            @media only screen and (max-width: $tablet-v) {
                margin-bottom: 40px;
            }

        }

        &>p {
            margin: 0;
            margin-bottom: 27px;
            color: $color-main;
            font-family: 'Montserrat', sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
        }
    }

    &:last-child {
        @media only screen and (max-width: $tablet-v) {
            margin-bottom: 0px;
        }
    }
}

.section-half>div {
    max-width: 1290px;
    padding: 0 40px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;

    @media only screen and (max-width: 1300px) {
        padding: 0 20px;
    }

    @media only screen and (max-width: $tablet-v) {
        flex-direction: column;
        align-items: center;
        padding: 0 40px;
    }

    @media only screen and (max-width: $phone-s) {
        padding: 0 20px;
    }
}

.section-full>div {
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 40px;

    @media only screen and (max-width: $phone-s) {
        padding: 0 20px;
    }
}

.section-full>div>div {
    background-color: $color-grey-five;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: 40px 30px 10px;

    &>img {
        max-width: 360px;
        width: 100%;

        @media only screen and (max-width: $tablet-v) {
            margin-bottom: 40px;
        }
    }

    @media only screen and (max-width: $tablet-v) {
        flex-direction: column;
        padding: 40px 40px 10px;
    }

    &>div {
        padding: 0 20px;
        display: flex;
        flex-direction: column;

        @media only screen and (max-width: $tablet-v) {
            margin-bottom: 40px;
            max-width: 433px;
            width: 100%;
        }

        &>h3 {
            color: $color-main;
            font-family: 'GothamPro', sans-serif;
            font-size: 35px;
            font-weight: 700;
            line-height: 45px;
            margin: 0;
            margin-bottom: 30px;
            max-width: 517px;
            width: 100%;

            @media only screen and (max-width: $tablet-h) {
                font-size: 27px;
                line-height: 31px;
            }

            @media only screen and (max-width: $phone-v) {
                font-size: 24px;
                line-height: 26px;
            }

            @media only screen and (max-width: $phone-s) {
                font-size: 20px;
                line-height: 22px;
                margin-bottom: 20px;
            }
        }

        &>img {
            margin-bottom: 45px;
            max-width: 229px;
            width: 100%;
        }

        &>p {
            margin: 0;
            margin-bottom: 27px;
            color: $color-main;
            font-family: 'Montserrat', sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            max-width: 559px;
            width: 100%;
        }
    }
}

.section-full-two>div>div {
    background-color: $color-blue-two;
    padding: 40px 0 64px;
}

.section-full-two>div>div>div>h3 {
    color: $color-white;
}

.section-full-two>div>div>div>p {
    color: $color-white;
}

.no-bg>div>div {
    background-color: transparent;
}