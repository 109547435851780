.tippy-box {
  border-radius: 0;
  &[data-theme~='light'] {
    color: #26323d;
    box-shadow: 0 0 36px 0 rgba(0, 0, 0, 0.11);
    background-color: white;
    &[data-placement^='top'] > .tippy-arrow::before {
      border-top-color: white;
    }
    &[data-placement^='bottom'] > .tippy-arrow::before {
      border-bottom-color: white;
    }
    &[data-placement^='left'] > .tippy-arrow::before {
      border-left-color: white;
    }
    &[data-placement^='right'] > .tippy-arrow::before {
      border-right-color: white;
    }
    & > .tippy-backdrop {
      background-color: white;
    }
    & > .tippy-svg-arrow {
      fill: white;
    }
  }
}
.tippy-content {
  padding: 32px;
}

.tooltip-content {
  display: none;
}
.tooltip-title {
  margin: 0 0 4px;
  font-family: 'GothamPro', sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.1;
}
.tooltip-text {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: $color-grey-two;
}
