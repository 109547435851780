.layout-preview {
    opacity: 0;
    transform: translateY(50px);

    &__top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 36px;
        align-items: center;

        @media only screen and (max-width: $tablet-v) {
            flex-direction: column;
            align-items: flex-start;
        }

        &--two {
            flex-direction: column;
            text-align: center;

            @media only screen and (max-width: $tablet-v) {
                align-items: center;
            }
        }

        &-box {
            display: flex;
            align-items: center;

            @media only screen and (max-width: $phone-h) {
                flex-wrap: wrap;
            }
        }

        &-title {
            margin: 0;
            color: $color-main;
            font-family: 'GothamPro', sans-serif;
            font-size: 40px;
            font-weight: 700;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            flex-wrap: nowrap;

            &:after {
                content: '';
                width: 1px;
                height: 20px;
                background-color: $color-blue-two;
                display: inline-block;
                margin: 0 20px;
            }

            &--two {
                font-size: 35px;
                margin-bottom: 8px;

                &:after {
                    display: none;
                }
            }
        }

        &-text {
            max-width: 365px;
            width: 100%;
            color: $color-grey-two;
            font-family: 'Montserrat', sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;

            @media only screen and (max-width: $phone-h) {
                margin-bottom: 5px;
            }
        }
    }

    &__content {
        @media only screen and (max-width: $tablet-v) {
            flex-direction: column;
            align-items: center;
        }

        &-link {
            display: block;
            margin-bottom: 25px;

            &--two {
                margin-bottom: 0;
            }
        }
    }

    &__pagination {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        &--modified {
            background-color: $color-grey-five;
            padding: 15px 0;
        }

        &-previous,
        &-next {
            width: 33px;
            height: 33px;
            background-color: $color-grey-one;
            background-image: url(../images/dark-arrow.svg);
            background-repeat: no-repeat;
            background-position: center;
            margin: 0 35px;
            border: 1px solid transparent;
            transition: all ease 0.4s;

            @media only screen and (max-width: $phone-v) {
                margin: 10px 35px;
                width: 100%;
            }

            &:hover {
                box-shadow: 0 1px 4px rgba(0, 34, 60, 0.34);
                border: 1px solid $color-blue-one;
                background-color: rgba(0, 101, 179, 0.03);
            }
        }

        &-next {
            transform: rotate(180deg);
        }

        &-item {
            width: 33px;
            height: 33px;
            background-color: $color-grey-one;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin: 0 6px;
            color: $color-grey-thirteen;
            font-family: 'Montserrat', sans-serif;
            font-size: 14px;
            font-weight: 700;
            border: 1px solid transparent;
            transition: all ease 0.4s;

            @media only screen and (max-width: $phone-v) {
                margin: 6px;
            }

            &:hover {
                box-shadow: 0 1px 4px rgba(0, 34, 60, 0.34);
                border: 1px solid $color-blue-one;
                background-color: rgba(0, 101, 179, 0.03);
            }

            &.active {
                box-shadow: 0 1px 4px rgba(0, 34, 60, 0.34);
                border: 1px solid $color-blue-one;
                background-color: rgba(0, 101, 179, 0.03);
            }
        }

        &-separator {
            position: relative;
            display: inline-flex;
            width: 20px;
            align-items: center;

            &:after {
                content: '';
                position: absolute;
                width: 20px;
                height: 2px;
                background-color: $color-main;
            }
        }
    }
}

.grid-box {
    margin: 0 -20px;
    display: flex;
    // justify-content: space-between;
    justify-content: center;
    flex-wrap: wrap;

    &--modified {
        margin: 0 -10px;
        justify-content: initial;
    }

    &__gutter {
        margin: -20px -40px;
    }

    &__item {
        max-width: 50%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        flex-basis: 28em;
        max-width: 448px;

        @media only screen and (max-width: $phone-v) {
            max-width: 100%;
        }

        &-two {
            padding: 20px 40px;
            max-width: 100%;

            @media only screen and (max-width: $phone-v) {
                padding: 0px 40px;
            }
        }

        &--modified {
            max-width: 25%;
            padding: 10px;

            @media only screen and (max-width: $desktop) {
                max-width: 50%;
            }

            @media only screen and (max-width: $phone-h) {
                max-width: 100%;
            }
        }
    }
}

.media-block {
    &__img {
        width: 100%;
    }

    &__link {
        display: flex;
        flex-direction: column;
        height: 100%;
        transition: all ease 0.4s;
        border: 1px solid transparent;

        &--modified {
            &:hover {
                box-shadow: 0 1px 4px rgba(0, 34, 60, 0.34);
                border: 1px solid $color-blue-one;
                background-color: rgba(0, 101, 179, 0.03);

                & .media-block__content-text {
                    color: $color-blue-one;
                }
            }
        }
    }

    &__content {
        padding: 20px 30px 30px 30px;
        border: 1px solid $color-grey;
        border-top: 0;
        flex: 1 0 auto;

        &--modified {
            border-top: 1px solid $color-grey;
        }

        &--modified-two {
            text-align: center;
            padding: 65px 17px 50px;
        }

        &-two {
            padding: 25px 0;
            padding-right: 30px;
        }

        &-box {
            padding-left: 25px;

            &--two {
                padding-left: 20px;
            }

            &--modified {
                padding-left: 0;
            }
        }

        &-title {
            color: $color-main;
            font-family: 'GothamPro', sans-serif;
            font-size: 24px;
            font-weight: 700;
            margin: 0;
            margin-bottom: 12px;

            &--two {
                font-size: 20px;
                font-weight: 500;
                line-height: 22px;
            }

            &--three {
                font-size: 27px;
                font-weight: 500;
                line-height: 31px;
                margin-bottom: 7px;
                word-break: break-word;
            }

            &--modified {
                color: $color-blue-one;
            }
        }

        &-text {
            color: $color-main;
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            margin: 0 auto;
            margin-bottom: 20px;
            max-width: 515px;
            width: 100%;
            margin-left: 0;
        }

        &-subtitle {
            color: $color-grey-three;
            font-family: Montserrat;
            font-size: 13px;
            font-weight: 400;
            margin: 0;
            margin-bottom: 7px;
        }
    }
}

.tutorial {
    &-video {
        &__span {
            color: $color-grey-three;
            font-family: 'Montserrat', sans-serif;
            font-size: 13px;
            font-weight: 400;
        }

        &__box {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &>span {
                margin-bottom: 0;
            }

            &:after {
                content: '';
                display: inline-flex;
                background-image: url(../images/video-play-btn.svg);
                background-repeat: no-repeat;
                background-position: center;
                padding: 20px;
            }
        }
    }
}