.news-inner {
    &__title {
        color: $color-main;
        font-family: 'GothamPro', sans-serif;
        font-size: 50px;
        font-weight: 700;
        line-height: 53px;
        text-align: center;
        margin: 0;
        margin-bottom: 40px;
    }
}