.cta {
    background-color: $color-blue-one;
    padding: 60px 0;
    // max-width: 1920px;
    width: 100%;
    margin: 0 auto;
    opacity: 0;
    transform: translateY(50px);
    @media only screen and (max-width: $desktop) {
        padding: 60px 20px;
    }
    @media only screen and (max-width: $phone-h) {
        padding: 60px 0px;
    }
    &__top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 54px;
        align-items: center;
        @media only screen and (max-width: $tablet-v) {
            flex-direction: column;
            align-items: flex-start;
        }
        &-box {
            display: flex;
            align-items: center;
            @media only screen and (max-width: $phone-h) {
                flex-wrap: wrap;
            }
        }
        &-title {
            margin: 0;
            color: $color-white;
            font-family: 'GothamPro', sans-serif;
            font-size: 40px;
            font-weight: 700;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            flex-wrap: nowrap;
            line-height: 47px;
            &:after {
                content: '';
                width: 1px;
                height: 20px;
                background-color: $color-white;
                display: inline-block;
                margin: 0 20px;
            }
        }
        &-text {
            max-width: 365px;
            width: 100%;
            opacity: 0.8;
            color: $color-white;
            font-family: 'Montserrat', sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            @media only screen and (max-width: $phone-h) {
                margin-bottom: 5px;
            }
        }
    }
    &-content {
        display: flex;
        justify-content: space-between;
        margin: 0 -10px;
        @media only screen and (max-width: 980px) {
            flex-wrap: wrap;
        }
        @media only screen and (max-width: $phone-h) {
            justify-content: flex-start;
        }
    }
    &-item {
        max-width: 237px;
        width: 100%;
        padding: 0 10px;
        @media only screen and (max-width: 980px) {
            margin-bottom: 36px;
        }
        &__title {
            color: $color-white;
            font-family: "GothamPro";
            font-size: 20px;
            font-weight: 500;
            line-height: 21px;
            margin: 0;
            margin-bottom: 11px;
        }
        &__text {
            color: $color-white;
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            margin: 0;
            margin-bottom: 27px;
            overflow: hidden;
            height: 5.6em;
            position: relative;
            &:after {
                content: "";
                text-align: right;
                position: absolute;
                bottom: 0;
                right: 0;
                width: 70%;
                height: 1.2em;
                background: linear-gradient(to right, rgba(255, 255, 255, 0), $color-blue-one 50%);
                @supports (-webkit-line-clamp: 4) {
                    display: none;
                }
            }
            @supports (-webkit-line-clamp: 4) {
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                max-height: 5.8em;
                height: auto;
                text-overflow: ellipsis;
            }
        }
    }
    &-two {
        background-color: $color-blue-one;
        padding: 40px 0 80px 0;
        &__top {
            &-title {
                color: $color-white;
                font-family: 'GothamPro', sans-serif;
                font-size: 40px;
                font-weight: 700;
                line-height: 47px;
                text-transform: uppercase;
                text-align: center;
                margin: 0;
                margin-bottom: 35px;
                @media only screen and (max-width: $tablet-v) {
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 30px;
                }
                // @media only screen and (max-width: $phone-s) {
                //     margin: -10px 0;
                // }
            }
        }
        &-content {
            display: flex;
            justify-content: space-around;
            margin: 0 -20px;
            @media only screen and (max-width: $tablet-v) {
                flex-direction: column;
                align-items: center;
            }
            &__title {
                color: $color-white;
                font-family: 'Montserrat', sans-serif;
                font-size: 24px;
                font-weight: 600;
                margin: 0;
                margin-bottom: 13px;
            }
            &__text {
                max-width: 404px;
                width: 100%;
                color: $color-white;
                font-family: 'Montserrat', sans-serif;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                margin: 0;
                margin-bottom: 27px;
            }
            &__side {
                padding: 0 20px;
                display: inline-flex;
                flex-direction: column;
                justify-content: center;
                @media only screen and (max-width: $tablet-v) {
                    margin-bottom: 40px;
                }
            }
            &__img {
                padding: 0 20px;
                // width: 100%;
                // max-width: 305px;
            }
        }
    }
    &-three {
        padding: 50px 0;
        &__top {
            &-title {
                color: $color-white;
                font-family: 'GothamPro', sans-serif;
                font-size: 40px;
                font-weight: 700;
                margin: 0;
                margin-bottom: 30px;
            }
        }
        &__content {
            &-list {
                &-item {
                    color: $color-white;
                    font-family: 'Montserrat', sans-serif;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 21px;
                    margin-left: -10px;
                    &:before {
                        content: '';
                        background-color: $color-white;
                        padding: 2px;
                        display: inline-flex;
                        vertical-align: middle;
                        border-radius: 50%;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
    &-four {
        &-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 70px 0;
            &__title {
                color: $color-white;
                font-family: 'GothamPro', sans-serif;
                font-size: 40px;
                font-weight: 700;
                margin: 0;
                margin-bottom: 12px;
                text-align: center;
            }
            &__text {
                color: $color-white;
                font-family: 'Montserrat', sans-serif;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                margin: 0;
                margin-bottom: 50px;
                max-width: 428px;
                width: 100%;
                text-align: center;
            }
        }
    }
    &-five {
        &-content {
            display: flex;
            align-items: center;
            margin: -35px;
            @media only screen and (max-width: $tablet-v) {
                flex-wrap: wrap;
                margin: 0;
            }
            &__title {
                color: $color-main;
                font-family: 'GothamPro', sans-serif;
                font-size: 35px;
                font-weight: 700;
                line-height: 45px;
                margin: 0;
                margin-bottom: 10px;
            }
            &__subtitle,
            &__text {
                font-family: 'Montserrat', sans-serif;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
            }
            &__subtitle {
                color: $color-grey-fourteen;
                margin: 0;
                margin-bottom: 15px;
            }
            &__text {
                color: $color-main;
                margin: 0;
                margin-bottom: 40px;
            }
            &__box {
                max-width: 470px;
                width: 100%;
                padding: 35px;
                @media only screen and (max-width: $tablet-v) {
                    max-width: 100%;
                    padding: 0;
                }
            }
            &__img {
                max-width: 800px;
                padding: 35px;
                width: 100%;
                &>img {
                    width: 100%;
                }
                @media only screen and (max-width: $tablet-v) {
                    padding: 0;
                    margin-bottom: 20px;
                }
            }
        }
    }
    &-six {
        &-content {
            display: flex;
            align-items: center;
            margin: -35px;
            @media only screen and (max-width: $tablet-h) {
                flex-wrap: wrap;
                margin: 0;
            }
            &__video {
                max-width: 800px;
                width: 100%;
                &-box {
                    position: relative;
                    &:after {
                        content: '';
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        position: absolute;
                        background-color: $color-main;
                        opacity: 0.4;
                    }
                    &.active {
                        &:after {
                            display: none !important;
                        }
                    }
                }
                &-btn {
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    margin: 35px !important;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    &.active {
                        display: none !important;
                    }
                }
            }
            &__title {
                color: $color-main;
                font-family: 'GothamPro', sans-serif;
                font-size: 35px;
                font-weight: 700;
                line-height: 45px;
                margin: 0;
                margin-bottom: 10px;
                &--modified {
                    font-size: 27px;
                    font-weight: 500;
                    line-height: 31px;
                }
            }
            &__subtitle,
            &__text {
                font-family: 'Montserrat', sans-serif;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
            }
            &__subtitle {
                color: $color-grey-fourteen;
                margin: 0;
                margin-bottom: 15px;
            }
            &__text {
                color: $color-main;
                margin: 0;
                margin-bottom: 40px;
            }
            &__box {
                width: 50%;
                padding: 35px;
                @media only screen and (max-width: $tablet-v) {
                    width: 100%;
                    padding: 15px;
                }
            }
            &__img {
                padding: 35px;
                position: relative;
                max-width: 800px;
                width: 100%;
                &>a {
                    display: block;
                }
                &>a>img {
                    width: 100%;
                }
                @media only screen and (max-width: $tablet-v) {
                    padding: 0;
                    margin-bottom: 20px;
                }
            }
        }
    }
    &-seven {
        &__title,
        &__text {
            color: $color-main;
            font-family: "GothamPro";
            font-size: 20px;
            font-weight: 500;
            line-height: 22px;
            margin: 0;
            margin-bottom: 10px;
        }
        &__text {
            margin: 0 15px;
            @media only screen and (max-width: $phone-h) {
                display: block;
            }
        }
        &__content {
            background-color: $color-grey-five;
            padding: 25px;
            text-align: center;
            @media only screen and (max-width: $phone-s) {
                padding: 15px;
            }
        }
        &__link {
            color: $color-blue-two;
            font-family: "GothamPro";
            font-size: 20px;
            font-weight: 500;
            line-height: 22px;
            @media only screen and (max-width: $phone-h) {
                word-break: break-all;
            }
            &--modified {
                font-size: 24px;
                font-weight: 700;
            }
        }
    }
    &-eight {
        &__top {
            margin-bottom: 50px;
            &-title {
                color: $color-main;
                font-family: 'GothamPro', sans-serif;
                font-size: 40px;
                font-weight: 700;
                text-align: center;
                margin: 0;
                margin-bottom: 8px;
            }
            &-text {
                color: $color-main;
                font-family: 'Montserrat', sans-serif;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                text-align: center;
                margin: 0;
                max-width: 452px;
                width: 100%;
                margin: 0 auto;
            }
        }
        &__title,
        &__text {
            color: $color-main;
            font-family: "GothamPro";
            font-size: 20px;
            font-weight: 500;
            line-height: 22px;
            margin: 0;
            margin-bottom: 10px;
        }
        &__text {
            margin: 0 15px;
            @media only screen and (max-width: $phone-h) {
                display: block;
            }
        }
        &__content {
            background-color: $color-grey-five;
            padding: 25px;
            text-align: center;
            @media only screen and (max-width: $phone-s) {
                padding: 15px;
            }
        }
        &__link {
            color: $color-blue-two;
            font-family: "GothamPro";
            font-size: 24px;
            font-weight: 700;
            line-height: 22px;
            @media only screen and (max-width: $phone-v) {
                font-size: 20px;
                font-weight: 600;
                word-break: break-all;
            }
        }
    }
}