.chess-box {
    &__item {
        display: flex;
        align-items: center;

        @media only screen and (max-width: $desktop) {
            flex-wrap: wrap;
            justify-content: center;
        }


        &-two {
            background-color: $color-grey-five;
        }

        &-img {
            width: 50%;
            display: flex;
            align-items: center;
            max-width: 540px;

            @media only screen and (max-width: $tablet-h) {
                width: 100%;
                justify-content: flex-end;
            }

            &-file {
                margin: -10px 10px 10px -10px;
                max-width: 583px;

                @media only screen and (max-width: 680px) {
                    max-width: 100%;
                    margin: 0;
                }
            }
        }

        &-title {
            color: $color-main;
            font-family: 'GothamPro', sans-serif;
            font-size: 40px;
            font-weight: 700;
            margin: 0;
            margin-bottom: 15px;

            @media only screen and (max-width: $tablet-v) {
                text-align: center;
            }

            @media only screen and (max-width: $phone-v) {
                font-size: 30px;
            }
        }

        &-paragraph {
            color: $color-main;
            font-family: 'Montserrat', sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            margin: 0;
            margin-bottom: 20px;
            max-width: 511px;
            width: 100%;
        }

        &-link {
            color: $color-main;
            font-family: 'Montserrat', sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            display: flex;
            align-items: center;
            position: relative;

            &--modified {
                justify-content: center;
                margin-top: 30px;
            }

            &:hover {
                &>.chess-box__item-link-text {
                    background-color: $color-main;
                }
            }

            &-text {
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0 2px 4px rgba(0, 34, 60, 0.36);
                border-radius: 2px;
                color: #fff;
                font-family: 'GothamPro', sans-serif;
                font-size: 14px;
                transition: all ease 0.4s;
                width: 100%;
                max-width: 188px;
                padding: 10px;
                margin-left: 15px;
                background-color: $color-blue-two;
            }
        }

        &-text {
            display: flex;
            flex-direction: column;
            padding: 0 1.25em;
            justify-content: center;
            width: 50%;

            @media only screen and (max-width: $desktop) {
                margin-bottom: 40px !important;
            }

            @media only screen and (max-width: $tablet-h) {
                width: 100%;
            }

            @media only screen and (max-width: $phone-v) {
                padding: 0;
            }
        }

        &-inner {
            display: flex;
            padding: 30px 0;
            flex-wrap: wrap;

            &-two {
                @media only screen and (max-width: $tablet-h) {
                    flex-direction: column-reverse;
                }

            }
        }
    }
}