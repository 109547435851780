.img-rotate {
    &__top {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 63px;
    }

    &__title {
        color: $color-main;
        font-family: 'GothamPro', sans-serif;
        font-size: 35px;
        font-weight: 700;
        margin: 0;
        margin-bottom: 6px;
        text-align: center;
    }

    &__subtitle {
        color: $color-grey-two;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        margin: 0;
        max-width: 328px;
        width: 100%;
        text-align: center;
    }
}

.ui-slider {
    background-color: transparent;
    height: 7px;
    position: relative;
    margin: 0 30px;
    height: 40px;

    &:after {
        content: '';
        position: absolute;
        height: 7px;
        width: 100%;
        background-color: #cfcfcf;
        top: 9px;
    }

    .ui-slider-handle {
        width: 60px;
        height: 40px;
        position: absolute;
        cursor: grab;
        padding: 30px 0;
        margin: -30px;
        top: 9px;

        &:after {
            content: '';
            position: absolute;
            height: 7px;
            width: 100%;
            background-color: $color-main;
            z-index: 4;
        }
    }
}

.spritespin-instance {
    cursor: ew-resize;
    margin: 0 auto;
    margin-bottom: 23px;
    padding-bottom: 50%;
    height: 0 !important;
    width: 100% !important;
}

.spritespin-canvas {
    position: absolute;
    top: 0 !important;
    left: 0 !important;
    width: auto !important;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}