.news-cta {
    background-color: $color-white;
    opacity: 0;
    transform: translateY(50px);

    &__top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 36px;
        align-items: center;

        @media only screen and (max-width: $tablet-v) {
            flex-direction: column;
            align-items: flex-start;
        }

        &-box {
            display: flex;
            align-items: center;

            @media only screen and (max-width: $phone-h) {
                flex-wrap: wrap;
            }
        }

        &-title {
            margin: 0;
            color: $color-main;
            font-family: 'GothamPro', sans-serif;
            font-size: 40px;
            font-weight: 700;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            flex-wrap: nowrap;
            line-height: 47px;

            &:after {
                content: '';
                width: 1px;
                height: 20px;
                background-color: $color-blue-two;
                display: inline-block;
                margin: 0 20px;
            }
        }

        &-text {
            max-width: 365px;
            width: 100%;
            opacity: 0.8;
            color: $color-grey-four;
            font-family: 'Montserrat', sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;

            @media only screen and (max-width: $phone-h) {
                margin-bottom: 5px;
            }
        }
    }

    &-content {
        display: flex;
        justify-content: space-between;
        margin: 0 -20px;

        @media only screen and (max-width: 980px) {
            flex-wrap: wrap;
            justify-content: center;
        }

    }

    &-item {
        max-width: 250px;
        width: 100%;
        margin: 0 20px;
        display: flex;
        flex-direction: column;

        @media only screen and (max-width: 980px) {
            margin-bottom: 36px;
        }

        @media only screen and (max-width: $phone-v) {
            max-width: 100%;
        }

        &__caption-time {
            display: block;
            color: $color-grey-three;
            font-family: 'Montserrat', sans-serif;
            font-size: 13px;
            font-weight: 400;
            margin-bottom: 4px;
            margin-top: 15px;
        }

        &__img {
            max-height: 225px;
            margin: 0 auto;
            display: block;
        }

        &__link {
            display: block;

            &:hover~.news-cta-item__title {
                color: $color-blue-one;
            }
        }

        &__title {
            color: $color-main;
            font-family: "GothamPro";
            font-size: 18px;
            font-weight: 500;
            line-height: 23px;
            margin: 0;
            overflow: hidden;
            height: 5em;
            position: relative;
            display: block;
            transition: all ease 0.4s;

            &:hover {
                color: $color-blue-one;
            }

            &:after {
                content: "";
                text-align: right;
                position: absolute;
                bottom: 0;
                right: 0;
                width: 70%;
                height: 1.2em;
                background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff 50%);

                @supports (-webkit-line-clamp: 4) {
                    display: none;
                }
            }

            @supports (-webkit-line-clamp: 4) {
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                max-height: 5em;
                height: auto;
                text-overflow: ellipsis;
            }
        }
    }
}