.slider {
    z-index: 1;

    &.open {
        z-index: 7;

        & .slick-track,
        & .slick-initialized,
        & .slick-slide {
            width: 100% !important;
        }
    }

    &-action {
        position: absolute;
        bottom: 0;
        width: 100%;
        margin-bottom: 45px;
        text-align: center;
        display: flex;
        justify-content: center;
        opacity: 0;
        transform: translateY(100px);
        z-index: 1;

        @media only screen and (max-width: 950px) {
            display: none;
        }

        &__content {
            &:last-child .slider-action__separator {
                display: none;
            }
        }

        &__link {
            color: $color-white;
            opacity: 0.59;
            font-family: 'GothamPro', sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            text-transform: uppercase;
            text-decoration: none;
            padding-bottom: 2px;
            transition: all ease 0.4s;

            &:hover {
                opacity: 1;
            }

            &.active {
                opacity: 1;
                border-bottom: 2px solid $color-white;
                font-weight: 700;
            }
        }

        &__separator {
            width: 1px;
            height: 25px;
            background-color: $color-white;
            display: inline-block;
            vertical-align: middle;
            margin: 0 39px;

            @media only screen and (max-width: 1080px) {
                margin: 0 20px;
            }
        }
    }

    &-img {
        position: relative;

        @media only screen and (max-width: $phone-v) {
            height: 580px !important;
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #00223c;
            opacity: 0.73;
            z-index: -1;

            @media only screen and (max-width: $phone-v) {
                z-index: 0;
            }
        }

        & div {
            background-position: center !important;
        }
    }

    &-section {
        height: 780px;
        display: flex !important;
        align-items: center;
        flex-direction: row;
        opacity: 0;
        transform: translateY(-50px);

        @media only screen and (max-width: $phone-v) {
            height: 580px;
        }
    }

    &-animation {
        width: 280px;
        height: 280px;

        @media only screen and (max-width: $phone-v) {
            width: 150px;
            height: 100px;
            margin-bottom: 15px;
        }
    }

    
    &-img--video {
        // display: grid;
        position: relative;
        grid-template-columns: 1fr;
        height: 780px;

        
        @media(max-width: $phone-v) {
            height: 582px;
        }
    }

    &-img--video &-img__box {
        grid-column: 1/-1;
        grid-row: 1/-1;
        z-index: 3;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #00223c;
            opacity: 0.73;
            z-index: 0;

            @media only screen and (max-width: $phone-v) {
                z-index: 0;
            }
        }

        .section {
            position: relative;
        }
    }
}

.slider-one {

    .slick-dots {
        position: absolute;
        bottom: 0;
        width: 100%;
        margin-bottom: 45px;
        text-align: center;
        display: flex;
        justify-content: center;

        @media only screen and (min-width: 950px) {
            display: none !important;
        }
    }

    .slick-dots li.slick-active button:before {
        background-color: $color-white !important;
        opacity: 1;
    }

    .slick-dots li {
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
    }

    .slick-dots li button {
        font-size: 0;
        line-height: 0;
        display: block;
        width: 20px;
        height: 20px;
        padding: 5px;
        cursor: pointer;
        color: transparent;
        border: 0;
        outline: none;
        background: transparent;
    }

    .slick-dots li button:before {
        content: '';
        font-size: 22px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        text-align: center;
        background-color: $color-white;
        opacity: 0.3;
    }

    .slider-nav .slick-list {
        display: none;
    }

    .slider-content,
    .slick-list {
        z-index: 1;

        &>.slick-track {
            display: flex !important;
            align-items: center;
        }
    }

    .slider-content {

        &__box {
            padding-left: 30px;

            @media only screen and (max-width: $phone-v) {
                padding-left: 0;
            }
        }

        &__title {
            color: $color-white;
            font-family: 'GothamPro', sans-serif;
            font-size: 46px;
            line-height: 57px;
            font-weight: 700;
            margin: 0;
            margin-bottom: 15px;
            max-width: 750px;
            width: 100%;

            @media only screen and (min-width: 1920px) {
                font-size: 75px;
                line-height: 80px;
            }

            @media only screen and (max-width: 950px) {
                font-size: 38px;
                line-height: 42px;
                text-align: center;
            }

            @media only screen and (max-width: $phone-h) {
                word-break: break-word;
            }

            @media only screen and (max-width: $phone-v) {
                font-size: 28px;
                line-height: 32px;
            }

            @media only screen and (max-width: $phone-s) {
                font-size: 35px;
                line-height: 40px;
            }
        }

        &__text {
            color: $color-white;
            font-family: 'Montserrat', sans-serif;
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
            margin: 0;
            margin-bottom: 35px;
            max-width: 470px;
            width: 100%;
        }

        &__img {
            display: inline-block !important;
            padding: 0 20px;

            @media only screen and (max-width: 950px) {
                margin-bottom: 40px;
            }

            @media only screen and (max-width: $phone-v) {
                max-width: 280px;
                width: 100%;
            }

            @media only screen and (max-width: $phone-s) {
                max-width: 143px;
                width: 100%;
                margin-bottom: 25px;
            }
        }

        &__side {
            width: 50%;
            padding: 0 20px;
            display: inline-flex;
            flex-direction: column;

            @media only screen and (max-width: 950px) {
                width: 100%;
                align-items: center;
            }

            @media only screen and (max-width: $phone-v) {
                padding: 0;
            }
        }
    }

    .slick-initialized .slick-slide {
        display: flex !important;
        justify-content: center;
        align-items: center;

        @media only screen and (max-width: 950px) {
            flex-direction: column-reverse;
            padding: 0 40px;
        }

        @media only screen and (max-width: 950px) {
            flex-direction: column-reverse;
            padding: 0 40px;
        }

        @media only screen and (max-width: $phone-s) {
            align-items: flex-start;
        }
    }

}