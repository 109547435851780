.info-box {
  &--modified {
    display: flex;
    align-items: center;
    margin: 0 -40px;

    @media only screen and (max-width: $tablet-h) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &__side {
    padding: 0 40px;
    max-width: 647px;
    width: 100%;

    & > img {
      width: 100%;
    }

    @media only screen and (max-width: $tablet-h) {
      margin-bottom: 20px;
    }
  }

  &__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  }

  &__title {
    color: $color-main;
    font-family: 'GothamPro', sans-serif;
    font-size: 35px;
    font-weight: 700;
    margin: 0;
    margin-bottom: 6px;
    text-align: center;

    &--modified {
      margin: 0;
      margin-bottom: 15px;
      color: $color-main;
      font-family: 'GothamPro';
      font-size: 27px;
      font-weight: 500;
      line-height: 31px;
    }
  }

  &__subtitle {
    color: $color-grey-two;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin: 0;
    text-align: center;
  }

  &__content {
    display: flex;
    justify-content: center;

    &--modified {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0 40px;
      width: 50%;

      @media only screen and (max-width: $tablet-h) {
        width: 100%;
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -40px;
    width: 100%;

    @media only screen and (max-width: $tablet-h) {
      justify-content: center;
    }

    &-item {
      color: $color-main;
      font-family: 'GothamPro', sans-serif;
      font-size: 20px;
      line-height: 21px;
      width: 50%;
      margin-bottom: 25px;
      padding: 0 40px;

      @media only screen and (max-width: $tablet-v) {
        padding: 0 20px;
      }

      @media only screen and (max-width: 550px) {
        width: 100%;
        padding: 0;
      }

      &-bold {
        font-weight: 500;
      }
    }
  }

  &-two {
    padding: 50px;

    @media only screen and (max-width: $phone-h) {
      padding: 50px 0;
    }

    &__title {
      color: $color-white;
      font-family: 'GothamPro', sans-serif;
      font-size: 40px;
      font-weight: 700;
      line-height: 47px;
      margin: 0;
      margin-bottom: 20px;
    }

    &__content {
      margin: 0 -20px;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
    }

    &__list {
      &-item {
        padding: 20px;
        max-width: 266px;
        width: 100%;
        flex-grow: 1;
        flex-basis: 17em;

        &-bold,
        &-thin {
          color: $color-white;
          line-height: 21px;
        }

        &-bold {
          font-weight: 500;
          font-size: 20px;
          font-family: 'GothamPro';
          margin: 0;
          margin-bottom: 10px;
        }

        &-thin {
          font-weight: 400;
          font-size: 14px;
          font-family: 'Montserrat', sans-serif;
          margin: 0;
        }
      }
    }
  }

  // &--mod {
  // }

  &--mod #{&} {
    &__list-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__list-item-icon {
      margin-left: 16px;
    }
  }
}
