.gallery-slider {
    background-color: $color-blue-two;

    &-js {
        visibility: hidden;

        &.active {
            visibility: visible;
        }

    }

    &__box {
        &-item {
            cursor: pointer;
        }
    }

    &--modified {
        background-color: $color-grey-five;
    }

    &__top {
        text-align: center;
        margin-bottom: 30px;

        &-title {
            margin: 0;
            margin-bottom: 10px;
            color: $color-main;
            font-family: 'GothamPro', sans-serif;
            font-size: 35px;
            font-weight: 700;
            line-height: 45px;
        }

        &-text {
            margin: 0;
            color: $color-main;
            font-family: 'Montserrat', sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
        }
    }

    &__btn {
        width: 30px;
        height: 30px;
        position: absolute;
        bottom: 20px;
        z-index: 1;
        max-width: 180px;
        width: 100%;

        @media only screen and (max-width: $phone-v) {
            bottom: -20px;
        }

        &-text {
            color: $color-white;
            font-family: 'Montserrat', sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
        }

        &-img {
            margin-right: 11px;
        }
    }

    &__content {
        display: flex;
        justify-content: space-around;
        align-items: center;


        @media only screen and (max-width: $tablet-v) {
            flex-direction: column;
        }

        &-box {
            width: 50%;
            position: relative;
            padding: 20px 20px 0 20px;

            &--two {
                display: flex;
                flex-direction: column;
            }

            @media only screen and (max-width: $tablet-v) {
                width: 100%;
                margin-bottom: 40px;
            }

            &--modified {
                width: 100%;
            }
        }
    }

    .slick-dots {
        position: absolute;
        bottom: 30px;
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        z-index: 1;
    }

    .slick-dots li.slick-active button:before {
        background-color: $color-white !important;
        opacity: 1;
    }

    .slick-dots li {
        position: relative;
        display: inline-block;
        width: 8px;
        height: 8px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
    }

    .slick-slide img {
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
        margin-bottom: 53px;

        @media only screen and (min-width: $tablet-v) {
            padding: 0 15px;
        }
    }

    .slick-dots li button {
        font-size: 0;
        line-height: 0;
        display: block;
        width: 8px;
        height: 8px;
        padding: 5px;
        cursor: pointer;
        color: transparent;
        border: 0;
        outline: none;
        background: transparent;
    }

    .slick-dots li button:before {
        content: '';
        font-size: 22px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        text-align: center;
        background-color: $color-white;
        opacity: 0.3;
    }

    .slider-nav .slick-list {
        display: none;
    }

    .slider-content,
    .slick-list {
        z-index: 1;
    }

    &__side {
        width: 50%;
        padding: 0 20px;

        @media only screen and (max-width: $tablet-v) {
            width: 100%;
        }

        @media only screen and (max-width: $phone-s) {
            padding: 0;
        }

        &-text {
            color: $color-white;
            font-family: 'Montserrat', sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            margin: 0;
            margin-bottom: 46px;
        }

        &-img {
            max-width: 280px;
            width: 100%;
            margin-bottom: 20px;
        }

        &-box {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -10px;

            @media only screen and (max-width: $phone-h) {
                flex-direction: column;
                align-items: center;
            }

            &-item {
                display: inline-flex;
                align-items: center;
                width: 50%;
                margin-bottom: 33px;
                padding: 0 10px;

                @media only screen and (max-width: $phone-h) {
                    width: 100%;
                    max-width: 300px;
                }
            }

            &-text {
                color: $color-white;
                font-family: 'Montserrat', sans-serif;
                font-size: 14px;
                font-weight: 400;
                line-height: 19px;
            }

            &-strong {
                color: $color-white;
                font-family: 'Montserrat', sans-serif;
                font-size: 14px;
                line-height: 19px;
                font-weight: 600;
            }

            &-img {
                margin-right: 20px;
            }
        }
    }

    &-two {

        &__close-btn {
            display: flex;
            align-items: center;
            z-index: 1;
            cursor: pointer;
            padding: 12px;
            width: 100%;
            text-align: center;
            justify-content: center;
            z-index: 1;
            cursor: pointer;
            padding: 20px;
            background-color: $color-white;
            border-top: 1px solid $color-grey;

            &-text {
                color: $color-main;
                font-family: 'Montserrat', sans-serif;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                padding: 0 10px;
            }

            &-img {
                padding: 0 10px;
            }
        }

        .slick-dots li button:before {
            background-color: $color-blue-two;
        }

        .slick-dots li.slick-active button:before {
            background-color: $color-blue-two !important;
            opacity: 1;
        }

        .tab-content>.tab-pane,
        .pill-content>.pill-pane {
            display: block;
            height: 0;
            overflow-y: hidden;
        }

        .tab-content>.active,
        .pill-content>.active {
            height: auto;
        }

        & .gallery-slider__content {
            align-items: flex-start;
        }

        & .slick-slide img {
            max-width: 530px;
        }
    }
}

.lg-outer .lg-thumb {
    margin: 0 auto;
}

.gallery-slider--modified {
    .slick-dots {
        position: absolute;
        bottom: 30px;
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        z-index: 1;
    }

    .slick-dots li.slick-active button:before {
        background-color: $color-main !important;
        opacity: 1;
    }

    .slick-dots li {
        position: relative;
        display: inline-block;
        width: 8px;
        height: 8px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
    }

    .slick-slide img {
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
        margin-bottom: 53px;
        margin-top: 53px;

        @media only screen and (min-width: $tablet-v) {
            padding: 0 15px;
        }
    }

    .slick-dots li button {
        font-size: 0;
        line-height: 0;
        display: block;
        width: 8px;
        height: 8px;
        padding: 5px;
        cursor: pointer;
        color: transparent;
        border: 0;
        outline: none;
        background: transparent;
    }

    .slick-dots li button:before {
        content: '';
        font-size: 22px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        text-align: center;
        background-color: $color-main;
        opacity: 0.2;
    }
}