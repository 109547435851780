.contact-form {
    &__box {
        display: flex;
        flex-wrap: wrap;
        background-color: $color-grey-five;
        padding: 15px 50px;

        @media only screen and (max-width: $phone-v) {
            padding: 15px;
        }
    }

    &__top {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;
    }

    &__title {
        color: $color-main;
        font-family: 'GothamPro', sans-serif;
        font-size: 35px;
        font-weight: 700;
        margin: 0;
        margin-bottom: 6px;
        text-align: center;
    }

    &__subtitle {
        color: $color-grey-two;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        margin: 0;
        max-width: 328px;
        width: 100%;
        text-align: center;
    }

    &__form {
        display: flex;
        flex-wrap: wrap;
    }

    &__item {
        position: relative;
        padding: 20px;
        flex-grow: 1;
        flex-basis: 23em;

        &-title {
            color: $color-main;
            font-family: "GothamPro";
            font-size: 27px;
            font-weight: 500;
            line-height: 31px;
            margin: 0 auto;
            margin-bottom: 10px;
            text-align: center;
        }

        &-subtitle {
            color: $color-main;
            font-family: 'Montserrat', sans-serif;
            font-size: 13px;
            font-weight: 400;
            margin: 0 auto;
            margin-bottom: 20px;
            display: block;
            text-align: center;
            max-width: 500px;
            width: 100%;
        }

        @media only screen and (max-width: 470px) {
            padding: 20px 0;
        }

        &--modified {
            flex-basis: 26em;
        }

        &--modified--three {
            flex-basis: 100%;
        }

        &--modified-two {
            padding: 30px 70px;

            @media only screen and (max-width: $phone-v) {
                padding: 30px 10px;
            }
        }

        &-label {
            display: block;
            position: absolute;
            top: 11px;
            left: 22px;
            color: $color-grey-two;
            font-family: 'Montserrat', sans-serif;
            font-size: 14px;
            font-weight: 400;
            opacity: 0;
            transform: translate3d(0, 50%, 0) scale(1);
            transform-origin: 0 0;
            transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1), transform 300ms cubic-bezier(0.645, 0.045, 0.355, 1), visibility 0ms 300ms cubic-bezier(0.645, 0.045, 0.355, 1), z-index 0ms 300ms cubic-bezier(0.645, 0.045, 0.355, 1);

            @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
                opacity: 1;
                top: -10px;
            }

            @media only screen and (max-width: 470px) {
                left: 0;
            }
        }

        &-input {
            display: block;
            margin: 0;
            padding: 14px 11px;
            width: 100%;
            border: 1px solid $color-grey-twelve;
            transition: all ease 300ms;
            opacity: 1;
            color: $color-grey-two;
            font-family: 'Montserrat', sans-serif;
            font-size: 13px;
            font-weight: 400;
            line-height: 1;

            @media only screen and (max-width: $phone-v) {
                font-size: 16px;
            }

            &.error {
                border: 1px solid $color-red;

                &:not(:placeholder-shown)+.contact-form__item-label {
                    opacity: 0.8;
                    color: $color-red;
                }
            }

            &::placeholder {
                opacity: 0.8;
                color: $color-grey-two;
                font-family: 'Montserrat', sans-serif;
                font-size: 13px;
                font-weight: 400;
            }

            &:hover {
                border: 1px solid $color-blue-one;
            }

            &:focus {
                outline: none;
                border: 1px solid $color-blue-one;

                &:not(:placeholder-shown)+.contact-form__item-label {
                    opacity: 0.8;
                    color: $color-blue-one;
                }
            }

            &::placeholder-shown+.contact-form__item-label {
                visibility: hidden;
                z-index: -1;
            }

            &:not(:placeholder-shown)+.contact-form__item-label,
            &:focus:not(:placeholder-shown)+.contact-form__item-label {
                visibility: visible;
                z-index: 1;
                opacity: 1;
                transform: translate3d(0, calc((100%) - (1.5rem)), 0) scale(0.8);
                transition: transform 300ms, visibility 300ms, z-index 300ms;
            }

            &--modified {
                height: 149px;
                overflow-y: auto;
                line-height: 1.4em;
                resize: none;
            }
        }
    }

    &__button {
        margin: 0 auto;
    }
}

.form-content__item {
    display: flex;
    align-items: center;
    padding: 0 1.875em 1.875em;
    align-self: flex-start;
}

.form-content__choice-checkbox {
    cursor: pointer;
    height: 0;
    width: 0;
    appearance: none;
    overflow: visible;
    box-sizing: border-box;
    padding: 0;
}

.form-content__choice-label {
    opacity: 0.8;
    color: $color-grey-two;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
    // margin-bottom: 6px;
    display: inline-flex;
    align-items: flex-start;
    user-select: none;
}

.form-content__choice-checkbox+.form-content__choice-checkbox-label:before,
.form-content__choice-checkbox+.form-content__choice-label:before,
.form-content__choice-checkbox+.form-content__choice-text:before {
    content: '';
    display: inline-block;
    margin: 0 10px 0 0;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    border: 1px solid $color-grey;
    background-color: $color-white;
    cursor: pointer;
    flex-shrink: 0;
    align-self: flex-start;
    background-image: url(../images/checkbox.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 0;
    transition: background 0.3s ease;
}

.form-content__choice-checkbox:checked+.form-content__choice-checkbox-label:before,
.form-content__choice-checkbox:checked+.form-content__choice-label:before,
.form-content__choice-checkbox:checked+.form-content__choice-text:before {
    content: '';
    background-size: 10px;
    border: 1px solid $color-blue-one;
}

.form-content__choice-checkbox:checked+.form-content__choice-label {
    color: $color-blue-two;
}

.form-content__wrapped {
    display: flex;
    margin: 0 -1.875em;

    @media only screen and (max-width: $tablet-v) {
        flex-wrap: wrap;
    }
}

.valid-input {
    position: relative;
}

.valid-input:after {
    position: absolute;
    content: '';
    background-image: url(images/checkbox.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 0;
}