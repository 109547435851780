.file-cta {
    &__top {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;
    }

    &__title {
        color: $color-main;
        font-family: 'GothamPro', sans-serif;
        font-size: 35px;
        font-weight: 700;
        margin: 0;
        margin-bottom: 6px;
        text-align: center;
    }

    &__subtitle {
        color: $color-grey-two;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        margin: 0;
        max-width: 328px;
        width: 100%;
        text-align: center;
    }

    &__content {
        position: relative;
        width: 50%;
        padding: 13px 20px;

        @media only screen and (max-width: $tablet-h) {
            width: 100%;
            margin-bottom: 20px;
        }

        @media only screen and (max-width: $phone-v) {
            padding: 13px 0;
        }

        &:last-child {
            @media only screen and (max-width: $tablet-h) {
                margin-bottom: 0;
            }
        }

        &-wrapper {
            display: flex;
            justify-content: space-between;
            margin: -13px -20px;
            flex-wrap: wrap;

            @media only screen and (max-width: $tablet-h) {
                flex-direction: column;
            }
        }

        &--two {
            z-index: 3;
        }
    }

    &__box {
        border: 1px solid $color-grey;
        display: flex;
        justify-content: space-between;
        padding: 40px 20px;
        transition: all ease 0.4s;
        align-items: center;
        height: 10%;

        @media only screen and (max-width: $tablet-v) {
            flex-direction: column;
        }

        &-img {
            margin: 0 10px;
        }

        &-half {
            width: 50%;

            @media only screen and (max-width: $tablet-v) {
                width: 100%;
                margin-bottom: 15px;
                text-align: center;
            }

        }

        &-text {
            color: $color-main;
            font-family: 'Montserrat', sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;

            @media only screen and (max-width: $phone-v) {
                display: block;
            }

        }

        @media only screen and (max-width: $phone-v) {
            flex-direction: column;
            align-items: center;
        }

        &:hover {
            border: 1px solid $color-blue-one;
            box-shadow: 0 1px 4px rgba(0, 34, 60, 0.34);
            background-color: rgba(0, 101, 179, 0.03);
        }

        &-title {
            color: $color-main;
            font-family: "GothamPro";
            font-size: 20px;
            font-weight: 500;
            line-height: 22px;
            margin: 0;
            margin-bottom: 8px;

            @media only screen and (max-width: $phone-v) {
                margin-bottom: 20px;
                text-align: center;
                font-size: 16px;
            }
        }

        &-link {
            color: $color-main;
            font-family: 'Montserrat', sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            margin: 0;
            padding: 40px 20px;
            margin: -40px -20px;

            &:before {
                content: '';
                background-image: url(../images/blue-arrow.svg);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                padding: 0 8px;
                margin-right: 8px;
            }
        }
    }

    &__list {
        display: none;
        margin: 0 20px;
        box-shadow: 0 1px 4px rgba(0, 34, 60, 0.34);
        border: 1px solid #0065b3;
        background-color: #F7FAFD;
        position: absolute;
        z-index: 2;
        left: 0px;
        right: 0;

        @media only screen and (max-width: $phone-v) {
            margin: 0;
        }

        &-item {
            padding: 23px 5px;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid $color-grey-ten;
            align-items: center;

            @media only screen and (max-width: $phone-v) {
                flex-direction: column;
                align-items: center;
            }

            &-title {
                color: $color-main;
                font-family: "GothamPro";
                font-size: 18px;
                font-weight: 500;
                line-height: 21px;
                padding: 0 10px;
                min-width: 250px;

                @media only screen and (max-width: $phone-v) {
                    margin-bottom: 8px;
                }
            }

            &-link {
                color: $color-main;
                font-family: 'Montserrat', sans-serif;
                font-size: 13px;
                font-weight: 400;
                line-height: 21px;
                transition: all ease 0.4s;
                padding: 20px 10px;
                margin: -20px 0;
                text-align: right;
                min-width: 245px;
                white-space: nowrap;

                &--modified {
                    font-size: 14px;
                    text-align: right;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    @media only screen and (max-width: $tablet-v) {
                        justify-content: center;
                    }
                }

                &:hover {
                    color: $color-blue-one;

                    & .file-cta__list-item-link-number {
                        color: $color-blue-one;
                    }
                }

                &-number {
                    color: $color-main;
                    font-family: 'Montserrat', sans-serif;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 21px;
                    transition: all ease 0.4s;

                    &--modified {
                        font-size: 14px;
                    }

                    &--two {
                        font-size: 14px;
                        color: $color-white;
                    }
                }

                &--two {
                    color: $color-white;
                    font-family: 'GothamPro', sans-serif;
                    font-size: 14px;
                    background-color: $color-main;
                    padding: 16px 12px;
                    box-shadow: 0 2px 4px rgba(0, 34, 60, 0.36);
                    border-radius: 2px;
                    margin: 0 auto;
                    transition: all ease 0.4s;

                    &:hover {
                        background-color: $color-blue-two;
                    }
                }
            }

            &-img {
                margin-right: 5px;
            }
        }
    }
}