.video-popup {
    z-index: 5;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    background-color: $color-black;
    display: none;

    &__box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;


        &-title {
            color: $color-white;
            font-family: 'GothamPro', sans-serif;
            font-size: 50px;
            font-weight: 700;
            line-height: 44px;
            margin: 0;
            margin-bottom: 12px;
        }

        &-text {
            color: $color-white;
            font-family: 'Montserrat', sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            margin: 0;
            margin-bottom: 43px;
            max-width: 365px;
            width: 100%;
            text-align: center;

        }
    }

    &__src {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__close-btn {
        position: absolute;
        right: 30px;
        top: 40px;
        display: block;
        z-index: 1;
        cursor: pointer;
        background-image: url(../images/close-btn.svg);
        background-size: cover;
        background-repeat: no-repeat;
        padding: 12px;
    }

    &__content {
        box-shadow: 0 2px 4px rgba(0, 34, 60, 0.36);
        background-color: $color-white;
        width: 60px;
        min-height: 60px;
        display: flex;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        // margin-bottom: 15px;
        transition: all ease 0.4s;
        position: relative;

        &:after {
            position: absolute;
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 10.5px 0 10.5px 19px;
            border-color: transparent transparent transparent $color-blue-one;
            transform: translateY(-50%) translateX(-50%);
            top: 50%;
            left: 58%;
        }
    }

    &__open-btn {
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: all ease 0.4s;
        padding: 40px;
        margin: -40px;

        &:hover {
            & .video-popup__content {
                background-color: $color-blue-one;

                &:after {
                    border-color: transparent transparent transparent $color-white;
                }
            }
        }


        &-text {
            color: $color-white;
            font-family: "GothamPro";
            font-size: 20px;
            font-weight: 500;
            line-height: 21px;
            margin-top: 20px;
        }
    }

    &__section {
        z-index: 7;
        width: 100%;
    }
}