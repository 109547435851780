.login-section {
    &__top {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        &-title {
            margin: 0;
            color: $color-main;
            font-family: 'GothamPro', sans-serif;
            font-size: 40px;
            font-weight: 700;
            display: flex;
            align-items: center;
            flex-wrap: nowrap;

            &:after {
                content: '';
                width: 1px;
                height: 20px;
                background-color: $color-blue-two;
                display: inline-block;
                margin: 0 20px;
            }
        }

        &-text {
            max-width: 365px;
            width: 100%;
            color: $color-grey-two;
            font-family: 'Montserrat', sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
        }
    }

    &__group {
        display: flex;

        opacity: 0;
        transform: translateY(50px);
        width: 100%;
        justify-content: spaee-between;

        @media only screen and (max-width: $phone-h) {
            flex-direction: column;
            align-items: center;
        }
    }

    &__middle {
        display: flex;
        justify-content: space-between;
        margin-bottom: 100px;
        align-items: center;
        position: relative;

        // &:after {
        //     content: '';
        //     display: block;
        //     background-image: url(../images/animated.svg);
        //     background-repeat: no-repeat;
        //     background-position: center;
        //     width: 40%;
        //     height: 520px;
        //     position: absolute;
        //     right: -30%;
        //     bottom: -125%;
        // }


        @media only screen and (max-width: $tablet-h) {
            flex-wrap: wrap;
            justify-content: center;
        }

        &-img {
            height: 100%;
            width: 50%;
            transform: translateX(100px);
            opacity: 0;
            margin-right: 50px;

            @media only screen and (max-width: $tablet-h) {
                margin-bottom: 40px;
            }

            @media only screen and (max-width: $phone-v) {
                opacity: 1;
                transform: matrix(1, 0, 0, 1, 0, 0);
            }

            &--modified {
                transform: translateX(0);
                opacity: 1;
                width: 100%;
                max-width: 700px;
            }
        }

        &-cnt {
            width: 50%;

            // opacity: 0;
            // transform: translateX(-100px);
            &--two {
                padding: 50px !important;
                background-color: $color-grey-five;
            }

            @media only screen and (max-width: $tablet-h) {
                width: 100%;
            }

            &-title {
                color: $color-main;
                font-family: "GothamPro";
                font-size: 27px;
                font-weight: 500;
                line-height: 31px;
                margin: 0;
                margin-bottom: 30px;
                max-width: 513px;
                width: 100%;
            }
        }

        &-box {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -10px;

            &-img {
                margin-right: 20px;
            }

            &-item {
                display: inline-flex;
                align-items: center;
                width: 50%;
                margin-bottom: 45px;
                padding: 0 10px;
                justify-content: flex-start;

                @media only screen and (max-width: $tablet-h) {
                    width: 33%;
                }

                @media only screen and (max-width: $tablet-v) {
                    width: 50%;
                }

                @media only screen and (max-width: $phone-v) {
                    width: 100%;
                }
            }

            &-text {
                max-width: 208px;
                width: 100%;
                color: $color-main;
                font-family: 'Montserrat', sans-serif;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                display: inline-block;
            }
        }
    }

    &__box {
        display: flex;
        align-items: center;
        margin: 0 -20px;

        @media only screen and (max-width: $tablet-h) {
            flex-direction: column;
        }

    }

    &__btm {
        text-align: center;

        &--modified {
            padding: 0 45px;
            padding-bottom: 50px;
        }

        &-title {
            margin: 0;
            margin-bottom: 15px;
            color: $color-main;
            font-family: "GothamPro";
            font-size: 27px;
            font-weight: 500;

            &--two {
                margin: 0;
                margin-bottom: 15px;
                color: $color-main;
                font-family: "GothamPro";
                font-size: 35px;
                font-weight: 700;
                line-height: 45px;
            }
        }

        &-text {
            margin: 0 auto;
            margin-bottom: 35px;
            max-width: 444px;
            width: 100%;
            color: $color-main;
            font-family: 'Montserrat', sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
        }
    }

    &__cta {
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        &--modified {
            flex-direction: row;
            justify-content: flex-start;
        }
    }
}

.cta-btn {
    &__text {
        color: $color-grey;
        font-family: 'GothamPro', sans-serif;
        font-size: 13px;

        &--modified {
            margin: 0 10px;
        }
    }

    &__link {
        color: $color-blue-two;
        text-decoration: underline;
    }
}