.text-row {

    &__box {
        display: none;

        &.active {
            display: block;
        }
    }

    &__top {
        margin-bottom: 50px;
        text-align: center;
    }

    &__title {
        color: $color-main;
        font-family: 'GothamPro', sans-serif;
        font-size: 40px;
        font-weight: 700;
        text-align: center;
        margin: 0;
        margin-bottom: 11px;
    }

    &__subtitle {
        color: $color-main;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        margin: 0 auto;
        max-width: 452px;
        width: 100%;
        display: block;
    }

    &__content {
        // display: none;

        &.active {
            display: block;
        }

        &-text {
            color: $color-main;
            font-family: 'Montserrat', sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            padding: 0px 25px 0px 17px;
            margin: 0;
            margin-bottom: 25px;
        }
    }

    &__text {
        color: $color-main;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        margin: 0;
    }

    &__title-two {
        color: $color-main;
        font-family: "GothamPro";
        font-size: 20px;
        font-weight: 500;
        line-height: 22px;
        margin: 0;
        margin-bottom: 15px;
    }

    &__list {
        background-color: $color-grey-five;
        padding: 20px 15px;

        &-item {
            color: $color-main;
            font-family: 'Montserrat', sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;

            &:before {
                content: '';
                background-color: #00223c;
                padding: 1px 3px;
                display: -webkit-inline-box;
                display: -ms-inline-flexbox;
                display: inline-flex;
                vertical-align: middle;
                margin-right: 3px;
            }
        }
    }

    &__item {
        border-bottom: 1px solid $color-grey-fifteen;

        &.active {
            border-bottom: 1px solid $color-blue-one;
        }

        &-link {
            cursor: pointer;
            display: block;
            padding: 25px 18px 25px 18px;
            text-decoration: none;
            outline: 0;
            color: $color-main;
            font-family: "GothamPro";
            font-size: 18px;
            font-weight: 500;
            line-height: 21px;
            transition: all ease 0.4s;

            &:hover {
                color: $color-blue-one;
            }
        }
    }
}