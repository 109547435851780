.title-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: $color-blue-two;
    padding: 7px 0 45px 0;
    margin-bottom: 40px;

    @media only screen and (max-width: $tablet-h) {
        padding: 20px 0 45px 0;
    }

    &__title {
        margin: 0;
        margin-bottom: 15px;
        color: $color-white;
        font-family: 'GothamPro', sans-serif;
        font-size: 50px;
        font-weight: 700;
        line-height: 44.5px;
        text-align: center;

        @media only screen and (max-width: $tablet-v) {
            font-size: 30px;
            line-height: 26px;
            font-weight: 600;
        }

        @media only screen and (max-width: $phone-v) {
            font-size: 24px;
            line-height: 26px;
        }
    }

    &__text {
        margin: 0;
        color: $color-white;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: 300;
        line-height: 21px;
        max-width: 403px;
        width: 100%;
        text-align: center;
    }
}