.content-table {
    display: block;
    width: 880px;
    height: 440px;
    padding: 0;
    background: transparent;
    font-size: 11px;
    margin: 0 auto;
    border-left: 2px solid $color-black;
    border-bottom: 2px solid $color-black;
    z-index: 10;
    position: relative;
}

.content-table caption {
    caption-side: top;
    width: 600px;
    text-transform: uppercase;
    letter-spacing: .5px;
    top: -40px;
    position: relative;
    z-index: 10;
    font-weight: bold;
}

.content-table tr,
.content-table th,
.content-table td {
    position: absolute;
    bottom: 0;
    min-width: 230px;
    z-index: 2;
    margin: 0;
    padding: 0;
    text-align: center;
}

.content-table td {
    transition: all .3s ease;
}

.content-table thead tr {
    left: 100%;
    top: 50%;
    bottom: auto;
    margin: -2.5em 0 0 5em;
}

.content-table thead th {
    width: 7.5em;
    height: auto;
    padding: 0.5em 1em;
}

.content-table thead th.content-table__block-bar--one {
    top: 0;
    left: 0;
    line-height: 2;
}

.content-table thead th.content-table__block-bar--two {
    top: 2.75em;
    line-height: 2;
    left: 0;
}

.content-table tbody tr {
    height: 296px;
    padding-top: 2px;
    color: #AAA;
}

.content-table .content-table__block {
    left: 65px;
}

.content-table .content-table__block-two th {
    font-weight: 500;
    font-size: 16px;
}

.content-table .content-table__block-two {
    left: 375px;
    width: 450px;
}

.content-table tbody th {
    bottom: -300px;
    position: relative;
    font-weight: normal;
    color: #333;
    width: 100%;
    font-size: 28px;
    display: block;
    max-width: 230px;
    margin: 0 auto;
}

.content-table .content-table__block-bar {
    width: 100%;
    border: 1px solid;
    border-bottom: none;
    color: #000;
    display: flex;
    align-items: center;
}

.content-table .content-table__block-bar p {
    margin: 5px 0 0;
    padding: 24px;
    font-size: 14px;
}

.content-table .content-table__block-bar--one {
    left: 0;
    height: 100%;
    background-color: #006cbf;
    color: #fff;
    border-color: transparent;
    transition: background ease 0.4s;

    &:hover {
        background-color: #314c61;
    }
}

.content-table .content-table__block-bar--two {
    left: 0;
    text-align: left;
    background-color: #d1d1cf;
    border-color: transparent;
    transition: background color ease 0.4s;

    &:hover {
        background-color: #6b6b69;
        color: $color-white;

        & ul {
            & li {
                &:before {
                    background-color: $color-white;
                }
            }
        }
    }

    & ul {
        padding: 24px;
        font-size: 14px;
        width: 100%;

        & li {
            padding-bottom: 8px;
            display: flex;
            align-items: center;


            &:before {
                content: '';
                display: block;
                padding: 2px;
                background-color: #63635d;
                border-radius: 50%;
                margin-right: 5px;
                transition: background ease 0.4s;
            }
        }
    }
}


.content-table__lines {
    position: relative;
    top: -450px;
    left: calc(50% - 10px);
    transform: translateX(-50%);
    width: 891px;
    height: 450px;
    z-index: 1;
    margin-bottom: -400px;
    font-size: 10px;
}

.content-table__lines .content-table__lines-item {
    position: relative;
    border-bottom: 1px solid #C4C4C4;
    width: 891px;
    height: 60px;
}

.content-table__lines .content-table__lines-item p {
    position: absolute;
    left: -5em;
    top: -0.8em;
    margin: 0 0 0 0.5em;
}