.slider-hero {

  &__picture--video {
    grid-column: 1/-1;
    grid-row: 1/-1;
    // min-height: 780px;
    height: 780px;
    max-height: 100vh;
   
    @media(max-width: $phone-v) {
      height: 582px;
    }
  }

  &__wrap {
    height: 780px;
    max-height: 100vh;
    z-index: 1;
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    
    @media(max-width: $phone-v) {
      height: 582px;
    }
  }

  &__picture--video &__video {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

.aspect-ratio {
  display: block;
  margin: 0;
  max-width: var(--width);
  position: relative;
  overflow: hidden;

  &::before {
      content: "";
      width: 1px;
      margin-left: -1px;
      float: left;
      height: 0;
      padding-bottom: calc(100% / (var(--aspect-ratio)));
      @media(max-width: $tablet-h) {
          padding-bottom: calc(100% / (var(--aspect-ratio-t, var(--aspect-ratio))));
      }
      @media(max-width: $tablet-v) {
          padding-bottom: calc(100% / (var(--aspect-ratio-sm, var(--aspect-ratio))));
      }
      @media(max-width: $phone-h) {
          padding-bottom: calc(100% / (var(--aspect-ratio-m, var(--aspect-ratio))));
      }
  }
  &::after {
      content: "";
      display: table;
      clear: both;
  }
  img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100% !important;
      width: 100% !important;
      margin: 0 !important;
      object-fit: cover;
  }
}
