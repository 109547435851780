.timeline {
    &__slider {
        &-two {
            max-width: 440px;
            width: 100%;

            &-item {
                max-height: 50vh;
                overflow: auto;
            }

            .slick-dots {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 10px;

                button {
                    width: 24px;
                    height: 24px;
                    display: flex;
                    font-size: 0;
                    justify-content: center;
                    align-items: center;
                    background-color: transparent;

                    &:before {
                        content: '';
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background-color: $color-white;
                        opacity: 0.4;
                    }
                }

                .slick-active {
                    button {
                        &:before {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        &-nav {
            position: fixed;
            top: 50%;
            left: 0;
            right: 0;

            &-item {
                cursor: pointer;
            }

            &>.slick-next,
            &>.slick-prev {
                font-size: 0;
                position: absolute;
                background-image: url(../images/arrow.svg);
                background-color: transparent;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                height: 20px;
                width: 20px;
                padding: 24px;
                bottom: 0;
                z-index: 5;
                top: 50%;
            }

            &>.slick-next {
                right: 0;
                transform: translateY(-50%) rotate(90deg);
            }

            &>.slick-prev {
                left: 0;
                transform: translateY(-50%) rotate(270deg);
            }

            &.slick-slider {
                text-align: center;
            }

            &-box {
                position: fixed;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                height: 100%;
                background-color: transparent;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                opacity: 0;
                z-index: 0;
                transition: all ease 0.3s;

                &.active {
                    z-index: 7;
                    opacity: 1;

                }
            }
        }

        &-item {
            display: flex !important;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            text-align: center;
        }

        &-title {
            color: $color-white;
            font-family: 'GothamPro', sans-serif;
            font-size: 40px;
            font-weight: 700;
            line-height: 47px;
            margin: 0;
            margin-bottom: 5px;
            opacity: 0.5;
        }

        &-text {
            color: $color-white;
            font-family: 'Montserrat', sans-serif;
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            margin: 0 auto;
            width: 100%;

            @media only screen and (max-width: $phone-h) {
                padding: 0 20px;
            }
        }

        &-btn {
            z-index: 6;
            position: absolute;
            bottom: 30px;
            left: 30px;
            background: transparent;
            text-decoration: underline;
            color: $color-white;
            font-family: 'Montserrat', sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
        }
    }

    &>.slick-slider>.slick-next,
    &>.slick-slider>.slick-prev {
        font-size: 0;
        position: absolute;
        transform: translateX(-50%) rotate(45deg);
        border-bottom: 2px solid $color-white;
        border-right: 2px solid $color-white;
        background: transparent;
        padding: 15px;
        bottom: 70px;
        z-index: 5;
        left: 50%;
        animation: mover 1s infinite alternate;

        @media only screen and (max-width: $phone-v) {
            bottom: 90px;
        }

        @media only screen and (max-width: $phone-s) {
            padding: 10px;
            bottom: 90px;
        }

        &:before {
            content: '';
            position: absolute;
            padding: 15px;
            background: transparent;
            border-bottom: 1px solid $color-white;
            border-right: 1px solid $color-white;
            transform: translate(0%) rotate(0deg);
            z-index: 5;
            // left: 50%;

            @media only screen and (max-width: $phone-s) {
                padding: 10px;
            }
        }
    }

    &>.slick-slider>.slick-prev {
        border-top: 2px solid #fff;
        border-left: 2px solid #fff;
        border-bottom: 0;
        border-right: 0;
        transform: translateY(-50%) rotate(270deg);
        z-index: 10;
        bottom: 85%;
        animation: moverreverse 1s infinite alternate;

        @media only screen and (max-width: $phone-v) {
            bottom: 80%;
        }

        @media only screen and (max-width: $phone-s) {
            bottom: 77%;
        }

        &:before {
            // right: 50%;
            transform: translate(0%) rotate(180deg);
        }
    }

    &>.slick-slider>.slick-list>.slick-track>.slick-slide {
        height: 100% !important;
        width: 100%;
        background-size: cover !important;
    }

    &>.slick-slider {
        height: 100%;
        position: absolute;
        // display: none;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 6;
    }

    &>.slick-slider>.slick-list,
    &>.slick-slider>.slick-list>.slick-track {
        height: 100vh !important;
        z-index: 5;
    }

    &>.slick-slider>.slick-list,
    &>.slick-slider>.slick-list>.slick-track {
        height: 100% !important;
    }

    &>.slick-slider>.slick-list>.slick-track>.slick-slide {
        border: none !important;
    }
}

@keyframes mover {
    0% {
        transform: translateY(0) rotate(45deg) translateX(-50%);
    }

    100% {
        transform: translateY(-20px) rotate(45deg) translateX(-50%);
    }
}

@keyframes moverreverse {
    0% {
        transform: translateY(-20px) rotate(45deg) translateX(-50%);
    }

    100% {
        transform: translateY(0) rotate(45deg) translateX(-50%);
    }
}


li.timeline__slider-item.slick-slide.slick-current.slick-active>h2 {
    opacity: 1;
}

li.timeline__slider-nav-item.js-nav-item.slick-slide.slick-current.slick-active.slick-center>h2 {
    opacity: 1;
}


// .timeline {
//     &__slider {
//         &--two {

//         }
//     }
// }