.product-accents {
  &__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 63px;
  }

  &__title {
    color: $color-main;
    font-family: 'GothamPro', sans-serif;
    font-size: 35px;
    font-weight: 700;
    margin: 0;
    margin-bottom: 6px;
    text-align: center;
  }

  &__subtitle {
    color: $color-grey-two;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin: 0;
    max-width: 266px;
    width: 100%;
    text-align: center;
  }

  &__count {
    @media only screen and (min-width: $tablet-h) {
      display: none;
    }

    &-two {
      @media only screen and (min-width: $tablet-h) {
        display: none;
      }
    }
  }

  &__body {
    position: relative;
    margin: 0 auto;
    &--s {
      max-width: 850px;
    }
  }

  &__body-img {
    width: 100%;
  }

  &__pin {
    position: absolute;
    width: 28px;
    height: 28px;
    @media only screen and (max-width: $phone-h) {
      width: 16px;
      height: 16px;
    }
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -32px 0 0 -32px;
      width: 64px;
      height: 64px;
      background-color: #70b6ec;
      border-radius: 50%;
      opacity: 0;
      animation: pulse 3s ease infinite;
      @media only screen and (max-width: $phone-h) {
        margin: -16px 0 0 -16px;
        width: 32px;
        height: 32px;
      }
    }
    &:hover::before {
      animation: none;
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      background-color: #016cbf;
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }
    // 1
    &--1-1 {
      top: 28%;
      left: 8%;
    }
    &--1-2 {
      top: 32%;
      left: 34.7%;
      @media only screen and (max-width: $phone-h) {
        left: 33.5%;
      }
    }
    &--1-3 {
      top: 67%;
      left: 44%;
    }
    &--1-4 {
      top: 32%;
      left: 47%;
      @media only screen and (max-width: $phone-h) {
        top: 28%;
        left: 46%;
      }
    }
    &--1-5 {
      top: 24%;
      left: 64.5%;
      @media only screen and (max-width: $phone-h) {
        left: 63%;
      }
    }
    &--1-6 {
      top: 66%;
      left: 88%;
    }
    // 2
    &--2-1 {
      top: 33%;
      left: 19%;
      @media only screen and (max-width: $phone-h) {
        top: 30%;
        left: 18%;
      }
    }
    &--2-2 {
      top: 17%;
      left: 28%;
    }
    &--2-3 {
      top: 74%;
      left: 35%;
      @media only screen and (max-width: $phone-h) {
        left: 34%;
      }
    }
    &--2-4 {
      top: 61%;
      left: 41%;
      @media only screen and (max-width: $phone-h) {
        top: 60%;
        left: 40%;
      }
    }
    &--2-5 {
      top: 57%;
      left: 68%;
      @media only screen and (max-width: $phone-h) {
        top: 55%;
        left: 67%;
      }
    }
    // 3
    &--3-1 {
      top: 27%;
      left: 16%;
    }
    &--3-2 {
      top: 51%;
      left: 45%;
    }
    &--3-3 {
      top: 71%;
      left: 55%;
    }
    &--3-4 {
      top: 28%;
      left: 58%;
      @media only screen and (max-width: $phone-h) {
        top: 27%;
        left: 57%;
      }
    }
  }

  &__content {
    display: flex;
    // justify-content: center;

    @media only screen and (max-width: $tablet-h) {
      flex-wrap: wrap;
      margin: 0 -20px;
    }

    &-main {
      // width: 100%;
      position: relative;

      @media only screen and (min-width: $tablet-h) {
        width: 100%;
        max-width: 585px;
        align-self: center;
      }

      @media only screen and (max-width: $tablet-h) {
        text-align: center;
        order: -1;
        margin: 0 auto;
        margin-bottom: 60px;
      }

      &-arrow {
        background-repeat: no-repeat;
        position: absolute;
        opacity: 0;

        @media only screen and (max-width: $tablet-h) {
          background-color: $color-blue-two;
          border-radius: 50%;
          padding: 20px;
          display: flex;
          width: 20px;
          height: 20px;
          align-items: center;
          justify-content: center;
          color: $color-white;
          font-family: 'GothamPro';
          font-size: 20px;
          font-weight: 500;
          user-select: none;
        }

        @media only screen and (max-width: $phone-v) {
          padding: 15px;
          font-size: 14px;
          opacity: 1 !important;
        }

        &-one {
          background-image: url(../images/blue-arrow-one.svg);
          width: 145px;
          height: 21px;
          top: 26%;
          left: -5%;

          @media only screen and (max-width: $tablet-h) {
            width: 20px;
            height: 20px;
            left: 15%;
          }
        }

        &-two {
          background-image: url(../images/blue-arrow-two.svg);
          width: 247px;
          height: 21px;
          right: 7%;
          top: 27%;

          @media only screen and (max-width: $tablet-h) {
            width: 20px;
            height: 20px;
            top: 50%;
            right: 48%;
          }
        }

        &-three {
          background-image: url(../images/blue-arrow-three.svg);
          width: 277px;
          height: 66px;
          left: -3%;
          top: 50%;

          @media only screen and (max-width: $tablet-h) {
            width: 20px;
            height: 20px;
            top: 26%;
            left: 56%;
          }
        }

        &-four {
          background-image: url(../images/blue-arrow-four.svg);
          width: 277px;
          height: 21px;
          right: 4%;
          bottom: 24%;

          @media only screen and (max-width: $tablet-h) {
            width: 20px;
            height: 20px;
            bottom: 24%;
            right: 41%;
          }
        }

        &-1 {
          background-image: url(../images/blue-arrow-one.svg);
          background-position: right center;
          width: 112px;
          height: 21px;
          top: 7%;
          left: 3%;
          transform: rotate(90deg);

          &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            background-image: url(../images/blue-arrow-one.svg);
            background-position: left center;
            width: 80px;
            height: 21px;
            transform: rotate(-90deg) translate(-40px, -42px);
          }

          @media only screen and (max-width: $tablet-h) {
            width: 20px;
            height: 20px;
            top: 30%;
            left: 9%;
            transform: none;

            &::before {
              display: none;
            }
          }
        }

        &-2 {
          background-image: url(../images/blue-arrow-one.svg);
          background-position: right center;
          width: 112px;
          height: 21px;
          top: 30%;
          left: 16%;

          &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            background-image: url(../images/blue-arrow-one.svg);
            background-position: left center;
            width: 120px;
            height: 21px;
            transform: translate(-100px, 0);
          }

          @media only screen and (max-width: $tablet-h) {
            width: 20px;
            height: 20px;
            left: 35%;

            &::before {
              display: none;
            }
          }
        }

        &-3 {
          background-image: url(../images/blue-arrow-one.svg);
          background-position: right center;
          width: 112px;
          height: 21px;
          top: 50%;
          left: 34%;
          transform: rotate(-90deg);

          &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            background-image: url(../images/blue-arrow-one.svg);
            background-position: left center;
            width: 120px;
            height: 21px;
            transform: translate(-100px, 0);
          }

          div {
            position: absolute;
            top: 0;
            left: 0;
            background-image: url(../images/blue-arrow-one.svg);
            background-position: left center;
            width: 120px;
            height: 21px;
            transform: translate(-220px, 0);

            &::before {
              content: '';
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              background-image: url(../images/blue-arrow-one.svg);
              background-position: left center;
              width: 120px;
              height: 21px;
              transform: rotate(90deg) translate(-60px, 59px);
            }

            &::after {
              content: '';
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              background-image: url(../images/blue-arrow-one.svg);
              background-position: left center;
              width: 120px;
              height: 21px;
              transform: rotate(90deg) translate(-178px, 59px);
            }
          }

          @media only screen and (max-width: $tablet-h) {
            width: 20px;
            height: 20px;
            top: 30%;
            left: 47%;
            transform: none;

            &::before,
            &::after,
            div {
              display: none;
            }
          }
        }

        &-4 {
          background-image: url(../images/blue-arrow-one.svg);
          background-position: right center;
          width: 112px;
          height: 21px;
          top: 2%;
          left: 48%;
          transform: rotate(90deg);

          &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            background-image: url(../images/blue-arrow-one.svg);
            background-position: left center;
            width: 80px;
            height: 21px;
            transform: translate(-81px, 0);
          }

          &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            background-image: url(../images/blue-arrow-one.svg);
            background-position: left center;
            width: 80px;
            height: 21px;
            transform: rotate(90deg) translate(-40px, 120px);
          }

          div {
            position: absolute;
            top: 0;
            left: 0;
            background-image: url(../images/blue-arrow-one.svg);
            background-position: left center;
            width: 120px;
            height: 21px;
            transform: rotate(90deg) translate(-140px, 140px);

            &::before {
              content: '';
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              background-image: url(../images/blue-arrow-one.svg);
              background-position: left center;
              width: 120px;
              height: 21px;
              transform: translate(-48px, 0px);
            }
          }

          @media only screen and (max-width: $tablet-h) {
            width: 20px;
            height: 20px;
            top: 25%;
            left: 64%;
            transform: none;

            &::before,
            &::after,
            div {
              display: none;
            }
          }
        }

        &-5 {
          background-image: url(../images/blue-arrow-one.svg);
          background-position: right center;
          width: 112px;
          height: 21px;
          top: 86%;
          left: 31%;
          transform: rotate(-90deg);

          &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            background-image: url(../images/blue-arrow-one.svg);
            background-position: left center;
            width: 80px;
            height: 21px;
            transform: translate(-81px, 0);
          }

          &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            background-image: url(../images/blue-arrow-one.svg);
            background-position: left center;
            width: 80px;
            height: 21px;
            transform: rotate(90deg) translate(40px, 120px);
          }

          div {
            position: absolute;
            top: 0;
            left: 0;
            background-image: url(../images/blue-arrow-one.svg);
            background-position: left center;
            width: 120px;
            height: 21px;
            transform: rotate(90deg) translate(140px, 140px);

            &::before {
              content: '';
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              background-image: url(../images/blue-arrow-one.svg);
              background-position: left center;
              width: 120px;
              height: 21px;
              transform: translate(119px, 0px);
            }
          }

          @media only screen and (max-width: $tablet-h) {
            width: 20px;
            height: 20px;
            top: 70%;
            left: 44%;
            transform: none;

            &::before,
            &::after,
            div {
              display: none;
            }
          }
        }

        &-6 {
          background-image: url(../images/blue-arrow-one.svg);
          background-position: right center;
          width: 112px;
          height: 21px;
          top: 64%;
          left: 75%;
          transform: rotate(180deg);

          &::before {
            content: '';
            display: block;
            position: absolute;
            top: -38px;
            left: -60px;
            background-image: url(../images/blue-arrow-one.svg);
            background-position: left center;
            width: 120px;
            height: 21px;
            transform: rotate(-90deg) translate(-100px, 0);
          }

          &::after {
            content: '';
            display: block;
            position: absolute;
            top: 121px;
            left: 57px;
            background-image: url(../images/blue-arrow-one.svg);
            background-position: left center;
            width: 44px;
            height: 21px;
            transform: translate(-100px, 0);
          }

          @media only screen and (max-width: $tablet-h) {
            width: 20px;
            height: 20px;
            top: 66%;
            left: 88%;
            transform: none;

            &::before,
            &::after {
              display: none;
            }
          }
        }

        &-1-1 {
          background-image: url(../images/blue-arrow-one.svg);
          width: 145px;
          height: 21px;
          top: 16%;
          left: 4%;

          @media only screen and (max-width: $tablet-h) {
            width: 20px;
            height: 20px;
            top: 18%;
            left: 26%;
          }
        }

        &-1-2 {
          background-image: url(../images/blue-arrow-1-2.svg);
          background-position: right center;
          width: 235px;
          height: 21px;
          top: 60%;
          left: 0%;

          @media only screen and (max-width: $tablet-h) {
            width: 20px;
            height: 20px;
            top: 60%;
            left: 40%;
          }
        }

        &-1-3 {
          background-image: url(../images/blue-arrow-1-3.svg);
          background-position: right top;
          width: 349px;
          height: 141px;
          top: 55%;
          left: 2%;

          @media only screen and (max-width: $tablet-h) {
            width: 20px;
            height: 20px;
            top: 53%;
            left: 66%;
          }
        }

        &-1-4 {
          background-image: url(../images/blue-arrow-1-4.svg);
          background-position: right bottom;
          width: 469px;
          height: 92px;
          top: 12%;
          left: 19%;

          @media only screen and (max-width: $tablet-h) {
            width: 20px;
            height: 20px;
            top: 32%;
            left: 18%;
          }
        }

        &-1-5 {
          background-image: url(../images/blue-arrow-1-5.svg);
          background-position: right bottom;
          width: 389px;
          height: 62px;
          top: 62%;
          left: 32%;

          @media only screen and (max-width: $tablet-h) {
            width: 20px;
            height: 20px;
            top: 74%;
            left: 34%;
          }
        }

        @media only screen and (max-width: $tablet-h) {
          background-image: none;
          margin-top: -10px;
          margin-left: -10px;
        }
      }
    }

    &-side {
      // margin-top: 50px;
      opacity: 0;
      transform: translateY(-50px);

      @media only screen and (min-width: $tablet-h) {
        width: 25%;
      }

      @media only screen and (max-width: $tablet-h) {
        margin-top: 0px;
        display: flex;
      }

      @media only screen and (max-width: $phone-v) {
        opacity: 1 !important;
        transform: matrix(1, 0, 0, 1, 0, 0) !important;
      }

      @media only screen and (max-width: $phone-h) {
        flex-direction: column;
      }

      &-title {
        color: $color-main;
        font-family: 'GothamPro';
        font-size: 20px;
        font-weight: 500;
        line-height: 22px;
        margin: 0;
        margin-bottom: 4px;
      }

      &-text {
        color: $color-main;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        margin: 0;
      }

      &-box {
        margin: 20px;

        @media only screen and (min-width: $tablet-h) {
          height: 50%;
        }

        @media only screen and (max-width: $tablet-h) {
          width: 50%;
          margin: 0;
          padding: 20px;
        }

        @media only screen and (max-width: $phone-h) {
          width: 100%;
        }

        &--modified {
          margin-bottom: 60px;

          @media only screen and (max-width: $tablet-h) {
            margin-top: 0px;
          }
        }

        &--mod {
          height: auto;
        }
      }
    }

    &-img {
      max-width: 475px;
      margin: 0 auto;
      display: block;
      margin-left: 25px;

      @media only screen and (max-width: $tablet-h) {
        margin: 0;
        max-width: 100%;
        margin-left: 0;
      }
    }
  }
}
