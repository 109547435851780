.main-header {
    position: absolute;
    z-index: 7;
    width: 100%;
    transition: all ease 0.4s;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-two {
        &.active {
            &>.main-header__content-two {
                background-color: $color-main;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }

        &--modified {
            & .main-header__side {
                @media only screen and (max-width: $phone-v) {
                    margin-right: 0;
                }
            }

            & .language {
                @media only screen and (max-width: $phone-s) {
                    margin: 0 !important;
                }
            }
        }
    }

    &.active {
        background-color: $color-main;
        z-index: 8;
        height: 100%;
    }

    &__btn {
        border-radius: 2px;
        background-color: $color-grey-one;
        color: $color-main;
        font-family: 'GothamPro', sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
        white-space: nowrap;
        padding: 9px 30px;
        margin: 0 20px;
        transition: all ease 0.4s;

        &-text {
            position: relative;
            top: 1px;
        }

        &:hover {
            background-color: $color-main;
            color: $color-white;
        }

        @media only screen and (max-width: $tablet-h) {
            margin-left: auto;
        }

        @media only screen and (max-width: $phone-v) {
            padding: 9px 16px 7px 9px;
            margin: 0;
            font-size: 13px;
            margin-right: 10px;
        }
    }

    &__list {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 0 10px;
        overflow: auto;
        height: 66px;
        overflow: hidden;

        &-two {
            justify-content: center;

            @media only screen and (max-width: $tablet-h) {
                justify-content: space-between;
            }
        }

        &-item {
            margin: 0 20px;
            white-space: nowrap;
            display: flex;
            align-items: center;
            border-bottom: 4px solid transparent;

            &.current {
                border-bottom: 4px solid $color-white;
            }

            &-link {
                color: $color-white;
                font-family: 'GothamPro', sans-serif;
                font-size: 14px;
                line-height: 21px;
                padding: 10px;

                &--two {
                    padding: 20px;
                    border-bottom: 4px solid transparent;

                    &.active {
                        border-bottom: 4px solid $color-white;
                    }
                }
            }
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // margin-bottom: 10%;
        transition: all ease 0.4s;
        position: absolute;
        top: 0;
        width: 100%;

        // padding-bottom: 30px;
        &-two {
            margin-bottom: 0;
            background-color: $color-blue-two;

            &--modified {
                & .site-logo {
                    display: none;
                }

                & .logo {
                    display: none;
                }
            }

            & .main-header__menu-burger {
                background-color: transparent;
                background-color: transparent;

                & .burger__content,
                & .burger__content:before,
                & .burger__content:after {
                    background-color: $color-white;
                    width: 28px;
                }
            }

            & .language {
                margin: 0 3px 0 7px;

                @media only screen and (max-width: $phone-v) {
                    margin: 0;
                }
            }

            & .main-header__menu-burger-box {
                width: 32px;
            }
        }
    }

    &__side {
        display: flex;
        align-items: center;
        min-width: 130px;
    }
}

.logo {
    margin: 0;

    &-two {
        & .site-logo {
            @media only screen and (max-width: $phone-v) {
                padding: 0 20px;
            }
        }
    }
}

.site-logo {
    padding: 0 40px;
    display: block;

    @media only screen and (max-width: $phone-v) {
        padding: 0 20px;
    }

    &__image {
        max-width: 180px;

        @media only screen and (max-width: 375px) {
            max-width: 137px;
            padding-bottom: 3px;
        }

        @media only screen and (max-width: $phone-s) {
            max-width: 100%;
        }
    }
}

.language {
    position: relative;

    &__choices {
        position: absolute;
        border: 1px solid gainsboro;
        border-top: none;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
        display: none;
    }

    &__text {
        color: $color-main;
        background-color: $color-white;
        padding: 10px 22px;
        display: block;
        transition: all ease 0.1s;
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
        text-transform: uppercase;
        text-decoration: none;

        &:hover {
            color: $color-white;
            background-color: $color-main;
        }

        &--item {
            padding: 22px;
            color: $color-white;
            background-color: transparent;
            font-family: 'GothamPro', sans-serif;

            &:hover {
                background-color: transparent;
            }
        }

        &-two {
            padding: 22px 10px;

            @media only screen and (max-width: $phone-v) {
                padding: 22px 7px;
            }
        }
    }
}


.burger {

    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 66px;
    height: 66px;
    background-color: $color-white;
    text-align: center;

    &.active .burger__content:before {
        top: -2px;
        transition: top .12s .18s cubic-bezier(.33333, 0, .66667, .33333), transform .15s .42s cubic-bezier(.215, .61, .355, 1);
        transform: rotate(-90deg);
    }

    &.active .burger__content:after {
        top: 0;
        opacity: 0;
        transition: top .3s cubic-bezier(.33333, 0, .66667, .33333), opacity .1s .27s linear;
    }

    &.active .burger__content {
        transition-delay: .4s;
        transition-timing-function: cubic-bezier(.215, .61, .355, 1);
        transform: translate3d(0, -10px, 0) rotate(-45deg);
    }

    &.active .burger__box {
        height: 22px;
    }

    &__box {
        position: relative;
        display: inline-block;
        width: 24px;
        height: 17px;
    }

    &__content {
        top: auto;
        bottom: 0;
        transition-delay: .15s;
        transition-timing-function: cubic-bezier(.55, .055, .675, .19);
        transition-duration: .15s;

        &:after,
        &:before {
            content: '';
            display: block;
        }

        &,
        &:after,
        &:before {
            position: absolute;
            display: block;
            margin-top: 2px;
            width: 24px;
            height: 2px;
            transition-timing-function: ease;
            transition-duration: .15s;
            transition-property: transform;
            background-color: $color-main;
            border-radius: 15px;
        }

        &:before {
            top: -9px;
            transition: top .12s .3s cubic-bezier(.33333, .66667, .66667, 1), transform .15s cubic-bezier(.55, .055, .675, .19);
        }

        &:after {
            top: -16px;
            transition: top .3s .3s cubic-bezier(.33333, .66667, .66667, 1), opacity .1s linear;
        }

    }

    &-two {
        width: auto;
        height: 55px;
        background-color: transparent;
        padding: 22px 15px;

        @media only screen and (max-width: $phone-v) {
            padding: 22px 15px 22px 7px;
        }

        &.active {
            background-color: $color-main;
        }

        & .burger__content,
        & .burger__content:after,
        & .burger__content:before {
            background-color: $color-white;
        }
    }
}





.navigation {
    display: none;
    // z-index: 3 !important;
    // width: 100%;
    // background-color: $color-main;
    text-align: center;
    margin-top: 66px;
    overflow: auto;
}

.main-navigation {
    &__list {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 35px;
        opacity: 0;
        transform: translateY(-20px);
        transition: transform 400ms ease, opacity 400ms ease;

        &.is-open {
            transform: translateY(0);
            opacity: 1;
        }

        &-item {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }

            &.active .main-navigation__list-link {
                opacity: 0.7;
                position: relative;
                pointer-events: none;

                &:after {
                    content: '';
                    padding: 2px 30px;
                    position: absolute;
                    bottom: 50%;
                    right: 50%;
                    -webkit-transform: translate(50%, 50%) rotate(340deg);
                    transform: translate(50%, 50%) rotate(340deg);
                    background-color: $color-grey-one;
                }
            }
        }

        &-link {
            color: $color-white;
            font-family: 'Montserrat', sans-serif Semi Bold;
            font-size: 24px;
            font-weight: 600;
            text-decoration: none;
            transition: all ease 0.4s;

            &:hover {
                opacity: 0.7;
            }

            @media only screen and (min-width: 1920px) {
                font-size: 29px;
            }

            @media only screen and (max-width: $phone-v) {
                font-size: 19px;
            }
        }
    }

    &__sublist {
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0 20px;

        &-link {
            color: $color-white;
            font-family: 'GothamPro';
            font-size: 20px;
            font-weight: 500;
            line-height: 21px;
            text-decoration: none;
            transition: all ease 0.4s;

            &:hover {
                opacity: 0.7;
            }

            @media only screen and (min-width: 1920px) {
                font-size: 24px;
            }
        }

        &-item {
            padding: 20px;
            margin: 10px;

            &.active>.main-navigation__sublist-link {
                opacity: 0.7;
                position: relative;
                pointer-events: none;

                &:after {
                    content: '';
                    padding: 2px 30px;
                    position: absolute;
                    bottom: 50%;
                    right: 50%;
                    -webkit-transform: translate(50%, 50%) rotate(340deg);
                    transform: translate(50%, 50%) rotate(340deg);
                    background-color: #f8f8f8;
                }
            }

            @media only screen and (max-width: $phone-v) {
                padding: 10px;
            }
        }
    }

    &__separator {
        max-width: 690px;
        width: 100%;
        height: 1px;
        margin: 0 auto;
        background-color: $color-grey-one;
        opacity: 0.3;
        margin-bottom: 35px;

        @media only screen and (max-width: $phone-v) {
            margin-bottom: 0;
        }
    }
}

.main-header__content-two>ul>li {
    border-bottom: none;
}